import React from "react";
import { Box, Typography } from "@mui/material";
import ImageRounded from "@mui/icons-material/ImageRounded";
const DatosVacio = ()=>{
    return(
             <Box 
         sx={{
            textAlign:'center',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
            width: "80vw",
          }}>
              <Box>
              <ImageRounded color="disabled" sx={{ fontSize: 200,}}/>
              <Typography  variant="h3" component="h2">No hay ninguna imagen creada</Typography>
              </Box>
             

        </Box>
       
    );
}
export default  DatosVacio;