import React from 'react';
import { Grid,Box } from "@mui/material";
import CardFormAutocomplete from './Autocomplet-Components/CardFormAutocomplete';
import AutoCompletExterior from './Autocomplet-Components/AutoCompletTipoExterior';
import AutoCompletColorPredominante from './Autocomplet-Components/AutoCompletColorPredominante';
import AutocompletePresupuesto from './Autocomplet-Components/AutocompletePresupuesto';
import AutocompleteTipoFotografia from './Autocomplet-Components/AutoCompleteTipoFotografia';
function OpcionesAdicionales(props){
    return (
        <div>
            <CardFormAutocomplete
                title="Dale un toque único a tu espacio"
            >
                <Grid container spacing={1}  alignItems="center">
                    <Grid item >
                        <AutocompleteTipoFotografia />
                    </Grid>

                    <Grid item >
                        <AutoCompletExterior />
                    </Grid>
                    
                    <Grid item >
                        <AutoCompletColorPredominante />
                    </Grid>

                    <Grid item >
                        <AutocompletePresupuesto />
                    </Grid>

                </Grid>
                <Box height="10px"/>
                {props.children}

            </CardFormAutocomplete>
        </div>

    );

}
export default OpcionesAdicionales;