import React from "react";
import {Container, Box} from '@mui/material';
import AppBarContent from "../VistaInicial/VistaInicial-components/AppBar";
import FooterContent from '../VistaInicial/VistaInicial-components/Footer/Footer';
import CreaNuevoPasswordForm from './RecuperaPassword-Components/CreaNuevoPasswordForm';



const CrearNuevoPassword  = () =>{
   

     return(
        <div>
            <AppBarContent/>
             <Container component="main" maxWidth="sm" sx={{mb:24}}>
                <Box
                    sx={{
                        width:"100%",
                        marginTop: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: 1, 
                    }}
                >
               <CreaNuevoPasswordForm/>
                </Box>
                
            </Container>
            <FooterContent/>
        </div>

    );

};
export default CrearNuevoPassword;