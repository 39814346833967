import React from "react";
import {Button, Card, CardActions, Link} from "@mui/material";
import imgInterior from '../../../assets/images/card-img/RenovArteTunueva.casa.png';


const CardTnunuevacasa = () =>{
    return(
        <div>
        <Link href="/Tunueva.casaInfo" underline="hover">
            <Card 
            variant="outlined"
            sx={{
                maxWidth:450,
                p: 2,
                }}
            >

                <img src={imgInterior}  alt="tunueva.casa.img" height="100%" width="100%"/>
                
                <CardActions disableSpacing >

                <Button 
                        variant="outlined"
                        size="small"
                    >
                        Disfruta Como Usuario De Tunueva.casa
                    </Button>
                
                </CardActions>
            </Card>
        </Link>
        </div>
    );
};
export default CardTnunuevacasa;