const Presupuesto =[
    {
        presupuesto:"Alto",
        traduction:"high"
    },
    {
        presupuesto:"Económico",
        traduction:"economic"
    },
    {
        presupuesto:"Medio",
        traduction:"medium"
    },
];
export default Presupuesto;