import React from "react";
import { Button, Card, CardActions, Link} from "@mui/material";
import imgAmic from '../../../assets/images/card-img/RenovArteAMIC.png';



const CardAmic = () =>{
    return(
        <div>
        <Link href="/AmicInfo" underline="hover">
            <Card 
            variant="outlined"
            sx={{
                maxWidth:450,
                p: 2,
                }}
        >

                <img src={imgAmic}  alt="tunueva.casa.img" height="100%" width="100%"/>
            
                <CardActions disableSpacing >

                <Button 
                    //onClick={handleNavigaateLogin}
                    variant="outlined"
                    size="small" 
                    
                >
                     Disfruta Como Usuario De AMIC
                </Button>
                
                </CardActions>
            </Card>
        </Link>
       </div>
    );
};
export default CardAmic;