import  React, {useState} from 'react';
import {Grid, TextField, Autocomplete} from '@mui/material';
import StylesItems from '../../../../Model/HabitacionStylesData';
import {Material, DecoExterior} from '../../../../Model/Exterior';
import { updateEstiloExterior, updateDecoracionExterior, updateMaterial } from './PrompComplete';



export default function AutoCompletStyle() {
  const [exterior, setExterior] = useState(null);
  const [material, setMaterial] = useState([]);
  const [decoExterior, setDecoExterior] = useState(null);
  
  //Variable que recorre la lista de estilos de decoración
  const StyleData = StylesItems.map((data)=>data.title);
  const MaterialData = Material.map((item) => item);
  const DecoExtData = DecoExterior.map((item) => item);
  return (
    <div>
      
      <Grid container spacing={1} >
        <Grid item>
            <Autocomplete
                size="small"
                value={exterior}
                onChange={(event, newValue) => {
                  if(newValue){
                    setExterior(newValue);
                    updateEstiloExterior(newValue);
                  }else{
                    setExterior(null);
                    updateEstiloExterior("");
                    setMaterial([]);
                    updateMaterial("");
                  }
                    
                    //handleChange(newValue);
                }}
                id="controllable-states-demo"
                options={StyleData}
                sx={{ width: 225, margin:"auto" }}
                renderInput={(params) => <TextField {...params} label="Estilo de exterior" />}
            />
        </Grid>

        <Grid item>
            {exterior !== null ? 
            <Autocomplete
                size="small"
                multiple
                //value={styles}
                onChange={(event, newValue) => {
                  if(newValue){
                    const traduction = newValue.map((item)=> item.traduction);
                    setMaterial(newValue);
                    updateMaterial(traduction);
                    
                    //console.log('traduction:', traduction);
                  }else{
                    setMaterial(null);
                    updateMaterial("");
                  }
                    
                }}
                id="tags-outlined"
                options={MaterialData}
                getOptionLabel={((option)=>option.material)}
                sx={{ width: 225, margin:"auto" }}
                renderInput={(params) => <TextField {...params} label="Material" placeholder='Material' />}
            />:
            null
            }
            
        </Grid>
        <Grid item>
            <Autocomplete
                size="small"
                //value={title}
                onChange={(event, newValue) => {
                  if(newValue){
                    setDecoExterior(newValue);
                    updateDecoracionExterior(newValue.traduction);
                  }else{
                    setDecoExterior(null);
                    updateDecoracionExterior("");
                  }
                    
                    //handleChange(newValue);
                }}
                id="controllable-states-demo"
                options={DecoExtData}
                getOptionLabel={((option)=>option.decoExt)}
                sx={{ width: 225, margin:"auto" }}
                renderInput={(params) => <TextField {...params} label="Decoración exterior" />}
            />
        </Grid>

      </Grid>
      

           
    </div>
  );
}