import React from "react";
import { Box, Fab } from '@mui/material';

const Button = (props) => {
    return (
        <Box >
            <Fab
                onClick={props.hadleAction}
                color={props.color}
                sx={{
                    height: "200%",
                    width: "150%",
                    mr: 1,
                    mb: {
                        xs: 1,
                        sm: 0,
                        lg: 0,
                        margin: 'auto',
                    },
                   ":hover":{
                       backgroundColor: props.hover
                   },

                }}>
               {props.children}
            </Fab>
        </Box>
    );

}
export default Button;