import { handleNavigate } from "../views/CrearCuenta/CrearCuenta-Components/Form";
import { almacenarTelefono, almacenarCorreo, almacenarTipoUsuario } from "../Model/FuncionAlmacenar";
import { SERVER_API_URL } from "../rules";


export async function EnviarEmail(email, telefono, tipoUser){
  try{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "correo": email,
      "phone_number": telefono,
    });

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    };
 
      const response = await fetch(`${SERVER_API_URL}/api/email`, requestOptions);
      if(!response.ok){
        const result = await response.json();
         
          return {send: false, success: true, messageErr: result.message, styleErr: "error"};
          //console.log('Status:', result);
          //throw new Error(`API request failed with status': ${response.status}`);
         
      }
      const result = await response.json();
      setTimeout(() => {
        
      }, 1000);
      handleNavigate();
      //Si el correo se envia almacenar el correo y teléfono en localStorage
      almacenarCorreo(email);
      almacenarTelefono(telefono);
      almacenarTipoUsuario(tipoUser);
      return {send: true, success: true, messageErr: result.message, styleErr: "success"};
    
  }catch(error){
   
      console.log("Error: ", error.message);
  }
    
};
