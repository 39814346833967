const TipoFotografia =[
    {tipoFotografia:"Recomendado", traduction:"recomended"},
    {tipoFotografia:"Redecorar", traduction:"Redecorate"},
    {tipoFotografia:"Cambio de color", traduction:"color change",
     area :[
            {objeto:"Armarios de cocina", traduction:"kitchen cabinets"},
            {objeto:"Cesped", traduction:"lawn"},
            {objeto:"Decoración", traduction:"decor"},
            {objeto:"Encimeras", traduction:"countertops"},
            {objeto:"Exterior", traduction:"exterior"},
            {objeto:"Fachada", traduction:"facade"},
            {objeto:"Iluminación", traduction:"lighting"},
            {objeto:"Muebles", traduction:"furniture"},
            {objeto:"Pared y Suelo", traduction:"wall & floor"},
            {objeto:"Paredes", traduction:"walls"},
            {objeto:"Paisajismo", traduction:"landscaping"},
            {objeto:"Piscina", traduction:"pool"},
            {objeto:"Puertas", traduction:"doors"},
            {objeto:"Sillas", traduction:"chairs"},
            {objeto:"Sofa", traduction:"couch"},
            {objeto:"Suelos", traduction:"floors"},
            {objeto:"Ventanas", traduction:"windows"},
           ] 
    },
    {tipoFotografia:"Dibujos y Renders", traduction:"Drawings and Renders"},
    {tipoFotografia:"Espacio vacío", traduction:"Empty space"},
    {tipoFotografia:"Mejorar y sustituir", traduction:"improve and replace"},
    {tipoFotografia:"Prueba de Muebles", traduction:"furnitures staging"},
    {tipoFotografia:"Vacia la habitación", traduction:"empty the room of furniture" },
];
export default TipoFotografia;