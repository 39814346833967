import  React, {useState} from 'react';
import {Grid, TextField, Autocomplete} from '@mui/material';
import ColorPredominantes from '../../../../Model/ColorPredominateData';
import { updateColorPredominante, updateColores } from './PrompComplete';



export default function AutoCompletColorPredominante() {
  const [colorFuente, setColorFuente] = useState(null);
  const [colores, setColores] = useState([]);
  const [index, setIndex] = useState(0);
 //Función que permite pasar el numero de arreglo seleccionado comparando el titulo para acceder a los estilos
  const handleChange = (value) => {
    if(!value){
      setIndex(0);
      return;
    }
    const newIndex = ColorPredominantes.findIndex((item) =>item.categoria === value);
            setIndex(newIndex);
  };
  //Variable que recorre la lista de estilos de decoración
  const ColorData = ColorPredominantes.map((data)=>data.categoria);
  const ColoresData = ColorPredominantes[index].colores.map((item)=>item.color);
  return (
    <div>
      <Grid container spacing={1}  >
        <Grid item>
            <Autocomplete
                size="small"
                value={colorFuente}
                onChange={(event, newValue) => {
                    setColorFuente(newValue);
                    handleChange(newValue);
                    setColores([]);
                    updateColorPredominante(newValue);
                    updateColores("");
                   
                }}
                id="controllable-states-demo"
                options={ColorData}
                sx={{ width: 225, margin:"auto" }}
                renderInput={(params) => <TextField {...params} label="Color Predomiante" />}
            />
           
        </Grid>

        <Grid item>
            {colorFuente !== null ? 
            <Autocomplete
                size="small"
                multiple
                value={colores}
                onChange={(event, newValue) => {
                  if(newValue){
                    setColores(newValue);
                    updateColores(newValue);
                  }else{
                    setColores(null);
                    updateColores("");
                  }
                    
                }}
                id="tags-outlined"
                options={ColoresData}
                sx={{ width: 225, margin:"auto" }}
                renderInput={(params) => <TextField {...params} label="Colores" placeholder='Colores' />}
            />:
            null
            }
         
        </Grid>
      </Grid>
      

           
    </div>
  );
}