import { SERVER_API_URL } from "../rules";
//Función para poder actualizar las visualizaciones o consultas al API roomInterior
export async function UpdateConsultConuntAPI(email){
    try{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        var raw = JSON.stringify({
          "email": email,
        });
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
        };

        const response = await fetch(`${SERVER_API_URL}/api/updateConsultAPI`, requestOptions);
        if(!response.ok){
            const result = await response.json();
            return {success: false, messageErr: result.message, styleErr: "error" }
        }

        const result = await response.json();
      
        return {success: result.showmessage, messageErr: result.message, styleErr: "success" };

    }catch(err){
       throw new Error(`API request faield with error:${err}`); 
    }

};