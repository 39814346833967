import { SERVER_API_URL } from "../rules";

export async function ConsultarPerfil(correo) {
    try {
      const response = await fetch(`${SERVER_API_URL}/api/usuarios/${correo}`);
  
      if (!response.ok) {
        const result = await response.json();
        return {usuario:[], succes: true, messageErr: result.message, styleErr: "error"}
      }
      const result = await response.json();
      return  {usuario:result,succes: false, messageErr: result.message, styleErr: "success"}
  
    } catch (error) {
      console.log("Error: ", error);
  
    }
  };

  export async function updateUsuario(password,correo) {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        "password": password,
        "correo": correo,
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
      };
  
    const response = await fetch(`${SERVER_API_URL}/api/updateUser`, requestOptions);
  
      if (!response.ok) {
        const result = await response.json();
       
        return {edit: false, success: true, messageErr: result.message, styleErr: "error" }
        //console.log(response.json());
        //throw new Error(`API request failed with status': ${response.status}`);
  
      }
      const result = await response.json();
     
      return {edit: true, success: true, messageErr: result.message, styleErr: "success" };
  
    } catch (error) {
      console.log("Error: ", error);
  
    }
  };