import React, { useState, useContext } from "react";
import { AuthContext } from "../../../routes/AuthContext";
import { useNavigate } from "react-router-dom";
import {Autocomplete, Box, TextField, Typography, InputAdornment, Button, CircularProgress } from "@mui/material";
import Alerta from "../../../components/AlertaComponent/Alerta";
import Theme from "../../../components/Theme/Theme"; 
import GmailIcon from "@mui/icons-material/EmailOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { EnviarEmail } from "../../../API/SendEmail";
import TipoUsuarioData from "../../../Model/TipoUsuarioData";



const CrearCuentaForm = () => {
    const [email, setEmail] = useState("");
    const [telefono, setTelefono] = useState("");
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const [style, setStyle] = useState("");
    const [isLoading, setIsloading] = useState(false);
    const [tipoUser, setTipoUser] = useState(null);

    const navigate = useNavigate();
    const {sendEmailSuccess} = useContext(AuthContext);

    const tipoUsuarioData = TipoUsuarioData.map((data) => data);

    //Función para enviar el email
  const handleCrearCuenta = async () => {
    if (email === '') {
      setError(true);
      MostrarAlerta();
      setMessage("Debe completar el campo vacío");
      setStyle("error")
      return;
    }
    if(telefono  === '') {
        setError(true);
        MostrarAlerta();
        setMessage("Debe completar el campo vacío");
        setStyle("error")
        return;
      }
      if(tipoUser  === null) {
        setError(true);
        MostrarAlerta();
        setMessage("Debe completar el campo vacío");
        setStyle("error")
        return;
      }
      if(telefono.length < 10 || telefono.length > 10) {
        setError(true);
        MostrarAlerta();
        setMessage("Ingrese un número de 10 digitos");
        setStyle("error")
        return;
      }
    setError(false);
    setIsloading(true);
    try{
        const {send, success, messageErr, styleErr} = await  EnviarEmail(email, telefono, tipoUser);
        if(success){
            MostrarAlerta();
            setMessage(messageErr);
            setStyle(styleErr);
            if(send){
                sendEmailSuccess();
            }
            return;
        }
    }catch (err){
        MostrarAlerta();
        setMessage("Ocurrio un error");
        setStyle("error");
    }finally{
        setIsloading(false);
    }
    
    
    
  };

   //Función para poder navegar a otra vista
   handleNavigate = () => {
    navigate("/verificarEmail?vista=Create-Account");
  };
   //Función para mostrar la Alerta de validación
   const MostrarAlerta = () => {
    setOpenAlert(true);
    setTimeout(() => {
      setOpenAlert(false);
    }, 3000)
  };

    return (
        <div>
            <Box
                sx={{
                    padding: "15px 30px",
                }}
                display="flex"
                alignItems="center"
            >
                <Box flexGrow={1} height="50%">
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: "32px",
                            fontWeight: "200",
                            mb: 2
                        }}
                    >
                       Crear Una Nueva Cuenta
                    </Typography>
                    <Typography
                        variant="h3"
                        sx={{
                            fontSize: "15px",
                            fontWeight: "100",
                            mb: 4
                        }}
                    >

                    </Typography>
                </Box>
            </Box>
            <form>
                <TextField
                    id="outlined-basic"
                    label="Ingrese un Correo Electrónico"
                    variant="outlined"
                    type="email"
                    value={email}
                    error={error}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <GmailIcon />
                        </InputAdornment>,
                    }}
                    color='error'
                    sx={{
                        mb: 4,
                    }}
                />
               <TextField
                    id="outlined-basic"
                    label="Ingrese su Número de WhatsApp"
                    variant="outlined"
                    type="phone"
                    value={telefono}
                    error={error}
                    onChange={(e) => setTelefono(e.target.value)}
                    fullWidth
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <WhatsAppIcon />
                        </InputAdornment>,
                    }}
                    color='error'
                    sx={{
                        mb: 4,
                    }}
                />

                <Autocomplete
                    disablePortal
                    fullWidth
                    onChange={(event, newValue) => {
                        if(newValue){
                            setTipoUser(newValue.tipousuario);
                            
                        }else{
                            setTipoUser(null);
                        }
                     }
                        
                    }
                    options={tipoUsuarioData}
                    getOptionLabel = {(option) => option.tipousuario}
                    sx={{
                        mb: 4,
                    }}
                    renderInput={(params) => <TextField error={error} color="error" {...params} label="¿Qué tipo de usuario eres ?" />}
                 />

               {isLoading ? 
               <Box alignItems="center" display="flex">
                   <Box sx={{paddingLeft: "50%",paddingRight: "50%",}}>
                    <Theme>
                        <CircularProgress color="red" />
                    </Theme>
                   
                   </Box>
                
               </Box>
               :
                <Button
                    variant="contained"
                    fullWidth
                    onClick={handleCrearCuenta}
                    sx={{
                        mb: 2,
                        backgroundColor: '#FC292B',
                        ":hover":{
                            backgroundColor: '#C21F22'
                        }
                    }}
                >
                    Crear Cuenta
                </Button>
               }

                

            </form>
            {openAlert && <Alerta openAlert={openAlert} message={message} style={style} />}

        </div>

    );

};
export default CrearCuentaForm;
export function handleNavigate(){};