import React, { useState } from "react";
import { ImageList } from "@mui/material";
import ImageListItem from '@mui/material/ImageListItem';
import DialogImageSelected from "./DialogImageSelected";
import ImageLoader from "../../../components/ImageLoadComponent/ImageLoader";

const ImageRespuesta = (props) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [imageSelected, setImageSelected] = useState("");

    //Funciones para mostrar el modal con la imagen más grande
    const handleModalOpen = (image) => {
        setImageSelected(image);
        setModalOpen(true);
    }
    const handleClose = () => setModalOpen(false);
    return (
        <div>
            <ImageList sx={{ width: "100%", height: "100%", margin: 'auto', }} cols={2}>
                {props.images.map((item) => (
                    <ImageListItem key={item} >

                        <ImageLoader
                            src={item}
                            alt="Image Result"
                            width="100%"
                            height="100%"
                            onClick={() => handleModalOpen(item)}
                            hasLogoWhitemark="true"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            {modalOpen && <DialogImageSelected open={modalOpen} img={imageSelected} close={handleClose} />}
        </div>
    );
}
export default ImageRespuesta;