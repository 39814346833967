import React from "react";
import AppBarContent from "../AppBar";
import FooterContent from "../Footer/Footer";
import {Grid, Card, Stack, Container, Box, Typography, Button} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ThemeColor from '../../../../components/Theme/Theme';
import VipImg from '../../../../assets/images/card-img/RenovArteVIP.png';
import ImagenVIP1 from '../../../../assets/images/card-img/ImagenVIP1.png';
import ImagenVIP5 from '../../../../assets/images/card-img/ImagenVIP5.png';

const PropuestaVip = () =>{

    const navigate = useNavigate();

    const handleLogin =() =>{
        navigate("/Login?vista=VIP");

    };

    return(
        <div>
        <AppBarContent/>
        <Stack
           direction="column"
           justifyContent="center"
           alignItems="center"
           sx={{
                marginTop:"100px"
               }}
        
        >
       {/*BLOQUE 1 */}
          <Grid 
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{mb:17}}
              spacing={2}
          >
              
              <Grid item >
                  <Container maxWidth="sm"> 
                  <Box>
                      <Typography variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", textAlign:"center"}} >
                        UNA EXCLUSIVA PLATAFORMA PARA LÍDERES INNOVADORES
                      </Typography>
                      
                      <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"justify"}} >
                        En RenovArte, hemos creado un espacio único y exclusivo: la Zona VIP. Este área está diseñada 
                        para aquellos que no solo reconocen el potencial de la innovación en el ecosistema inmobiliario 
                        y de la construcción, sino que también están dispuestos a ser parte activa de esta transformación. 
                        El acceso a la Zona VIP es exclusivo para quienes desean contribuir activamente con 
                        comentarios, ideas y propuestas que resuenen con nuestra visión de un mercado más sostenible y 
                        eficiente
                      </Typography>
                  </Box>
                  </Container>
                 
              </Grid>

              <Grid item 
                    sx={{
                          display: "flex",
                          alignItems: "stretch",
                      }}>
                  <Card 
                  variant="outlined"
                  sx={{
                      maxWidth:450,
                      p: 0,
                     
                   }}
                  >
                  <img src={VipImg}  alt="nevacasa.img" height="100%" width="100%"/>
                  </Card>

              </Grid>

          </Grid>
                  
              {/*BLOQUE 2 */}
              <Typography variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", textAlign:"center"}} >
                  UNA COMUNIDAD DE PENSADORES AVANZADOS
              </Typography>
              <Container sx={{mb:4}} >
                  <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"justify", marginRight:"5%", marginLeft:"5%"}} >
                    La admisión a nuestra Zona VIP se basa en el mérito de las ideas y el compromiso con la 
                    sostenibilidad y la innovación. Es un lugar para aquellos que ven más allá del status quo y están 
                    listos para discutir, colaborar y formar alianzas estratégicas que impulsen una transformación 
                    real y significativa en el sector. Aquí, tu voz y tus ideas tienen el poder de moldear el futuro de la 
                    construcción y el inmobiliario
                  </Typography>
              </Container>

              <Box sx={{alignContent:"center", mb:17}}>
                                <ThemeColor>
                                   <Button variant="contained" style={{ color: 'white' }}  color="blue" onClick={handleLogin}>
                                            Iniciar Sesión
                                    </Button> 
                                </ThemeColor>
                </Box>

              {/*BLOQUE 3 */}
                  <Grid 
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{mb:17}}
                  spacing={2}
              >
                  <Grid item 
                      sx={{
                              display: "flex",
                              alignItems: "stretch",
                          }}>
                      <Card 
                      variant="outlined"
                      sx={{
                          maxWidth:450,
                          p: 0,
                      
                      }}
                      >
                      <img src={ImagenVIP1}  alt="nevacasa.img" height="100%" width="100%"/>
                      </Card>

                  </Grid>
                  
                  <Grid item >
                      <Container maxWidth="sm"> 
                      <Box>
                          <Typography variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", textAlign:"center"}} >
                              TRANSFORMACIÓN IMPULSADA POR LA COLABORACIÓN
                          </Typography>
                          
                          <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"justify"}} >
                            En la Zona VIP, cada comentario y cada conversación tiene el potencial de desencadenar 
                            innovaciones que redefinirán la industria. Esta es una de las muchas iniciativas que hemos 
                            lanzado en colaboración con líderes de la industria inmobiliaria y de la construcción, enfocadas 
                            en crear oportunidades de negocio que no solo sean viables económicamente, sino también 
                            sostenibles para nuestro planeta.
                          </Typography>
                      </Box>
                      </Container>
                  </Grid>

              </Grid>

                {/*BLOQUE 4 */}

                <Grid 
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{mb:17}}
                  spacing={2}
              >
                  
                  <Grid item >
                      <Container maxWidth="sm"> 
                      <Box>
                          <Typography variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", textAlign:"center"}} >
                              CREE VALOR SOSTENIBLE PARA LA SOCIEDAD
                          </Typography>
                          
                          <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"justify"}} >
                            Su participación en la Zona VIP de RenovArte significa un compromiso con el cambio, la 
                            innovación y la profesionalidad. Es una oportunidad para influir en cómo las propiedades son 
                            concebidas, construidas y renovadas, impactando positivamente en la comunidad y en la 
                            sociedad en general. Estamos construyendo un legado de prácticas éticas y sostenibles que 
                            fomentan un entorno mejor y más responsable.
                          </Typography>
                      </Box>
                      </Container>
                  </Grid>

                  <Grid item 
                      sx={{
                              display: "flex",
                              alignItems: "stretch",
                          }}>
                      <Card 
                      variant="outlined"
                      sx={{
                          maxWidth:450,
                          p: 0,
                      
                      }}
                      >
                      <img src={ImagenVIP5}  alt="nevacasa.img" height="100%" width="100%"/>
                      </Card>

                  </Grid>

              </Grid>
                
                {/*BLOQUE 5 */}
              <Container  sx={{mb:17}}>
                  <Box  display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ bgcolor: '#F6641F', marginRight:"5%", marginLeft:"5%", borderRadius:5, }}>
                    <Typography variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", color:"white", paddingTop:"10%", textAlign:"center"}} >
                            ÚNETE A NOSOTROS
                    </Typography>

                    <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"center", color:"white", marginRight:"5%", marginLeft:"5%", mb:4}} >
                        Si estás listo para hacer más que observar el cambio, si estás listo para liderarlo y dar forma a un 
                        futuro sostenible, te invitamos a compartir tu visión en la Zona VIP de RenovArte. Explora cómo 
                        tu perspectiva puede convertirse en parte de nuestra misión colectiva para revolucionar la 
                        industria.
                    </Typography>

                    <Box sx={{paddingBottom:"5%",}}>
                                <ThemeColor>
                                   <Button variant="contained"  style={{ color: '#1E386E', fontWeight:"bold", }}  color="white" onClick={handleLogin}>
                                            Iniciar Sesión
                                    </Button> 
                                </ThemeColor>
                    </Box>

                  </Box>

              </Container>
        </Stack>
        <FooterContent/>
  </div>

    );

};
export default PropuestaVip;