import React from "react";
import {Button, Card, CardActions, Link} from "@mui/material";
import imgVip from '../../../assets/images/card-img/RenovArteVIP.png';


const CardVIP = () =>{

    return(
        <div>
        <Link href="/VipInfo" underline="hover">
            <Card 
            variant="outlined"
            sx={{
                maxWidth:450,
                p: 2,
            }}
         >
 
            <img src={imgVip}  alt="Vip.img" height="100%" width="100%"/>
             
             <CardActions disableSpacing >
 
             <Button 
                     //onClick={handleNavigaateLogin}
                     variant="outlined"
                     size="small"  
                 >
                       Disfruta Como Usuario De Zona VIP
                 </Button>
                 
             </CardActions>
 
            </Card>
        </Link>
        </div>
    );
};
export default CardVIP;