import React, {useContext} from "react";
import { Navigate, useLocation } from "react-router-dom";
import {AuthContext} from './AuthContext';
//Función para mantener lasa rutasa protegidsa
const ProtectedRoute =({
    redirectPath = '/',
    roles ={},
    children,
})  =>{
    const {isAutenticated, userRole} = useContext(AuthContext);
    const location = useLocation();
    
    if(!isAutenticated){
        return <Navigate to={redirectPath} state={{from: location }} replace/>;
    }

    // Verificar el rol del usuario y permitir el acceso a las rutas correspondientes
    if (userRole === 3) {
        // Rol 3 tiene acceso a todas las rutas
        return children;
    } else if (userRole === 2) {
        // Rol 2 tiene acceso a las rutas definidas para el rol 2 y 1
        if (roles[2] || roles[1]) {
            return children;
        }
    } else if (userRole === 1) {
        // Rol 1 tiene acceso a las rutas definidas para el rol 1
        if (roles[1]) {
            return children;
        }
    }
    // Si el usuario no tiene acceso a la ruta, redirigirlo
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
    //return <Outlet/>;

};
export default ProtectedRoute;

export const ProtectedRouteAdmin = ({
    redirectPath = '/',
    children,
}) =>{
    const {isAutenticatedAdmin} = useContext(AuthContext);
    const location = useLocation();

    if(!isAutenticatedAdmin){
        return <Navigate to={redirectPath} state={{from: location }} replace/>;
    }
    return children;
    //return <Outlet/>;

};

export const ProtectedRouteEmail = ({
    redirectPath = '/',
    children,
}) =>{
    const {sendEmail} = useContext(AuthContext);
    const location = useLocation();

    if(!sendEmail){
        return <Navigate to={redirectPath} state={{from: location }} replace/>;
    }
    return children;
    //return <Outlet/>;
};
export const ProtectedRoutePassword = ({
    redirectPath = '/',
    children,
}) =>{
    const {veryfyClave} = useContext(AuthContext);
    const location = useLocation();

    if(!veryfyClave){
        return <Navigate to={redirectPath} state={{from: location }} replace/>;
    }
    return children;
    //return <Outlet/>;
};



