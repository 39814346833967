import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import {
  Box,
  Drawer,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { SidebarWidth } from "../../../assets/global/Theme-variable";
import logoicon from "../../../assets/images/logos/Tunueva.casa.png";
import LogoIcon from "../Logo/LogoIcon";
import Menuitems from "./data";
import Logo from './LogoRenovArte';


const Sidebar = (props) => {
  const [open, setOpen] = React.useState(true);
  const { pathname } = useLocation();
  const location = useLocation();
  const pathDirect = pathname;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const currentView1 = location.pathname.split('/')[1];
  const currentView = location.pathname.split('/')[2];
  
  const filterMenuOptions = Menuitems.filter(option => option.vista ===currentView ||  option.vista ===currentView1 );

  
  const handleClick = (index) => {
    if (open === index) {
      setOpen((prevopen) => !prevopen);
    } else {
      setOpen(index);
    }
  };

  const SidebarContent = (
    <Box sx={{ p: 3, height: "calc(100vh - 40px)" }}>
      
        <Box sx={{ display: "flex", alignItems: "Center" }}>
          {currentView1 ==='Tunueva.casa' ?
            <img alt="Logo" src={logoicon} width="200px"/>
            :
            <LogoIcon />
          }
          
        </Box>
      

      <Box>
        <List
          sx={{
            mt: 4,
          }}
        >
          {filterMenuOptions.map((item, index) => {
            //{/********SubHeader**********/}

            return (
              <List component="li" disablePadding key={item.title}>
                <ListItem
                  onClick={() => handleClick(index)}
                  button
                  component={NavLink}
                  to={item.href}
                  selected={pathDirect === item.href}
                  sx={{
                    mb: 1,
                    ...(pathDirect === item.href && {
                      color: "white",
                      backgroundColor: currentView1 === 'Tunueva.casa' ? '#FC292B!important':'#42a5f5!important',
                      ":hover":{
                        backgroundColor: currentView1 === 'Tunueva.casa' ? '#C21F22!important':'#1565c0!important'
                      }
                    }),
                  }}
                >
                  <ListItemIcon
                    sx={{
                      ...(pathDirect === item.href && { color: "white" }),
                    }}
                  >
                    <item.icon width="20" height="20" />
                  </ListItemIcon>
                  <ListItemText>{item.title}</ListItemText>
                </ListItem>
              </List>
            );
          })}
        </List>
      </Box>
      {currentView1 === 'Tunueva.casa' ?
        <Logo/>
        :
        <></>
      }
    </Box>
  );
  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={props.isSidebarOpen}
        variant="persistent"
        PaperProps={{
          sx: {
            width: SidebarWidth,
          },
        }}
      >
        {SidebarContent}
      </Drawer>
    );
  }
  return (
    <Drawer
      anchor="left"
      open={props.isMobileSidebarOpen}
      onClose={props.onSidebarClose}
      PaperProps={{
        sx: {
          width: SidebarWidth,
        },
      }}
      variant="temporary"
    >
      {SidebarContent}
    </Drawer>
  );
};

export default Sidebar;
