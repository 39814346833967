import React from 'react';
import { Grid, Typography, Link} from '@mui/material';
import "./footer.css";
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import TelephoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/EmailOutlined';

const FooterComponent = () => {

    return (

        <footer>
            <div className="footer-container" >
                <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start" sx={{ paddingTop: '5%', paddingBottom: '5%' }}>

                    <Grid item>
                        <div className="footer-section">
                            <Typography variant="h2" gutterBottom>Enlaces</Typography>
                            <ul>
                                <li >
                                    <Link className="link" href="https://tunueva.casa/" target="_blank" underline="hover" sx={{color:"#fff", ':hover':{color:"#FC292B"}, }}>
                                        <Typography > Tunueva.casa</Typography>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="http://amicjalisco.org/" target="_blank" underline="hover" sx={{color:"#fff", ':hover':{color:"#FC292B"}}}>
                                        <Typography >Amic</Typography>
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </Grid>

                    <Grid item >
                        <div className="footer-section">
                            <Typography variant="h2" gutterBottom>Ayuda</Typography>
                            <ul>
                                <li><Link href="/Renovarte" sx={{color:"#fff", ':hover':{color:"#FC292B"}}} underline="hover"> 
                                    <Typography>¿Qué es RenovArte?</Typography></Link>
                                </li>
                                <li><Link href="/Tunueva.casaInfo" sx={{color:"#fff", ':hover':{color:"#FC292B"}}} underline="hover">
                                     <Typography>¿Qué es Tunueva.casa?</Typography></Link>
                                </li>
                                <li><Link href="/AmicInfo" sx={{color:"#fff", ':hover':{color:"#FC292B"}}}underline="hover"> 
                                    <Typography>¿Qué es AMIC?</Typography> </Link>
                                </li>
                                <li><Link href="/VipInfo" sx={{color:"#fff", ':hover':{color:"#FC292B"}}}underline="hover"> 
                                    <Typography>¿Qué es la zona VIP?</Typography> </Link>
                                </li>
                            </ul>
                        </div>
                    </Grid>

                    <Grid item >
                        <div className="footer-section">
                            <Typography variant="h2" gutterBottom> Contacto</Typography>

                            <Grid container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                                sx={{ mb: 1 }}

                            >
                                <Grid item>
                                    <LocationIcon sx={{color:"#FC292B"}} />
                                </Grid>

                                <Grid item >
                                    <Typography>Guadalajara Jalisco</Typography>
                                </Grid>

                            </Grid>

                            <Grid container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                                sx={{ mb: 1 }}
                            >
                                <Grid item>
                                    <TelephoneIcon sx={{color:"#FC292B"}} />
                                </Grid>
                                <Grid item>
                                    <Typography><span style={{ marginRight: '3.6em' }}>CDMX</span>(55)1204-0150</Typography>
                                </Grid>
                            </Grid>

                            <Grid container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                                sx={{ mb: 1 }}
                            >
                                <Grid item>
                                    <TelephoneIcon sx={{color:"#FC292B"}} />
                                </Grid>
                                <Grid item>
                                    <Typography><span style={{ marginRight: '1em' }}>Guadalajara</span>(33) 1031-0303</Typography> 
                                </Grid>
                            </Grid>

                            <Grid container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                                sx={{ mb: 1 }}
                            >
                                <Grid item>
                                    <TelephoneIcon sx={{color:"#FC292B"}} />
                                </Grid>
                                <Grid item>
                                    <Typography> <span style={{ marginRight: '1.7em' }}>Monterrey</span>(81) 1107-1154</Typography>
                                </Grid>
                            </Grid>

                            <Grid container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                            >
                                <Grid item>
                                    <EmailIcon sx={{color:"#FC292B"}} />
                                </Grid>
                                <Grid item>
                                    <Typography>ventas@evolutel.com</Typography>
                                </Grid>
                            </Grid>


                        </div>
                    </Grid>
                </Grid>

            </div>

            <div className='borderLine'>
                <Typography>© 2024 All rights reserved by RenovArte  </Typography>
            </div>




        </footer>
    );


};
export default FooterComponent;