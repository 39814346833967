import React, {useState}from 'react';
import {Alert,Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, TextField, Button} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Alerta from '../AlertaComponent/Alerta';
import { MaxResolutionEmail } from '../../API/ShareImg';

const DialogFormEmail = (props) =>{
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState(" ");
    const [style, setStyle] = useState("");

    const shareImg =() =>{
        if(email === ""){
            MostrarAlerta();
            setMessage('Debes ingresar un correo electrónico');
            setStyle('error');
            setError(true);
            return;
        }
        setError(false);
        sendEmail();
    };

    const sendEmail = async() =>{
        setLoading(true);
        try{
            const {send, success, messageErr, style} = await MaxResolutionEmail(email, props.ImgURL);
            if(success){
                MostrarAlerta();
                setMessage(messageErr);
                setStyle(style);
                if(send){
                    setTimeout(()=>{
                        props.handleClose();
                    },3000);
                }
                return;
            }

        }catch(err){
            MostrarAlerta();
            setMessage("Ocurrio un error al intentar enviar la imagen");
            setStyle("error");
            return;
        }finally{
            setLoading(false);
        }
    };

    const MostrarAlerta = () =>{
        setOpenAlert(true);
        setTimeout(() =>{
            setOpenAlert(false);
        },3000)

    };

    return(
        <div>
        <Dialog
        sx={{
            margin:'auto'
        }}
        open={props.open}
        >
            <DialogTitle>Compartir Por Correo Electrónico</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Ingrese el correo electrónico al que desea compartir esta imagen
                </DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="email"
                    label="Correo Electrónico"
                    type="email"
                    value={email}
                    onChange ={(e => setEmail(e.target.value))}
                    fullWidth
                    variant="standard"
                    error={error}
               />

            </DialogContent>
            <DialogActions>
                <Button
                onClick={props.handleClose}
                >
                    Cancelar
                </Button>
                <LoadingButton loading={loading} loadingIndicator="Compartiendo" onClick={shareImg} >Compartir</LoadingButton>
            </DialogActions>
           {loading && <Alert variant="outlined" severity="info">
                Se esta obteniendo la imagen con alta resolución, el envió a su correo puede demorar un poco!
            </Alert>
           }
        </Dialog>
        {openAlert && <Alerta openAlert={openAlert} style={style} message={message}></Alerta>}
        </div>
        
    );

};
export default DialogFormEmail;