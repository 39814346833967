import React from "react";
import { Button, Card, CardActions, Link} from "@mui/material";
import imgNuevoAliado from '../../../assets/images/card-img/RenovArteNuevoAliado.png';



const CardNuevoAliado = () =>{
    return(
        <div>
        <Link href="" underline="hover">
            <Card 
            variant="outlined"
            sx={{
                maxWidth:450,
                p: 2,
            }}
         >
 
            <img src={imgNuevoAliado}  alt="nevoaliado.img" height="100%" width="100%"/>
             
             <CardActions disableSpacing >
 
             <Button 
                     //onClick={handleNavigaateLogin}
                     variant="outlined"
                     size="small" 
                     
                 >
                      Disfruta Como Usuario 
                 </Button>
                 
             </CardActions>
 
            </Card>
        </Link>
        </div>
    );
};
export default CardNuevoAliado;