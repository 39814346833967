const TipoUsuarioData = [
    { tipousuario: "Agente inmobiliario" },
    { tipousuario: "Agente inmobiliario Keller Williams"},
    { tipousuario: "Construcción"},
    { tipousuario: " Diseñador de interiores"},
    { tipousuario: "Paisajista"},
    { tipousuario: "Entusiasta del diseño"},
    { tipousuario: "Usuario independiente"},

];
export default TipoUsuarioData;