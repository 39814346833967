const Exterior =[
    {exterior:"Area de Piscina al Aire Libre", traduction:"Outdoor Pool Area"},
    {exterior:"Balcón", traduction:"Balcony"},
    {exterior:"Fachada", traduction:"Facade"},
    {exterior:"Jardín al Aire Libre", traduction:"Outdoor Garden"},
    {exterior:"Patio al Aire Libre", traduction:"Outdoor Patio"},
];

 const  Material=[
    {material:"Acero estructural", traduction:"Structural steel"},
    {material:"Concreto expuesto", traduction:"Exposed concrete"},
    {material:"Ladrillo visto", traduction:"Exposed brick"},
    {material:"Madera tratada (para detalles o revestimientos)", traduction:"Treated wood (for details or coatings)"},
    {material:"Piedra natural (como mármol o granito)", traduction:"Natural stone (such as marble or granite)"},
    {material:"Paneles metálicos (aluminio o acero inoxidable)", traduction:"Metal panels (aluminum or stainless steel)"},
    {material:"Vidrio templado de gran formato", traduction:"Large format tempered glass"},
    
];
 const DecoExterior=[
    {decoExt: "Caminos  decorativos", traduction:"Decorative paths"},
    {decoExt: "Esculturas ", traduction:"Sculptures"},
    {decoExt: "Fuentes", traduction:"Fountains"},
    {decoExt: "Jardin y macetas", traduction:"Garden and pots"},
    {decoExt: "Relojes de exterior", traduction:"Outdoor clocks"},
    

];
export {Exterior, Material, DecoExterior};