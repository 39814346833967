import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../routes/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import {Box, TextField, Typography,InputAdornment, Button, CircularProgress} from  '@mui/material';
import GmailIcon from "@mui/icons-material/EmailOutlined";
import Alerta from '../../../components/AlertaComponent/Alerta';
import Theme from '../../../components/Theme/Theme';
import { RecuperaPassword } from '../../../API/RecuperaPassword';

const RecuperaPasswordForm = () =>{
    const [email, setEmail] = useState("");
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const [style, setStyle] = useState("");
    const [isLoading, setIsloading] = useState(false);

    const navigate = useNavigate();

    const location  = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const vistaParam = queryParams.get('vista');

    const {sendEmailSuccess} = useContext(AuthContext);

    const handleEnviarCodigo = async () =>{
        if (email === '') {
            setError(true);
            MostrarAlerta();
            setMessage("Debe completar el campo");
            setStyle("error")
            return;
          }

          setError(false);
          setIsloading(true);

          try{
            const {send, success, messageErr, styleErr} = await RecuperaPassword(email);
            if(success){
                MostrarAlerta();
                setMessage(messageErr);
                setStyle(styleErr);
                if(send){
                    sendEmailSuccess();
                }
                return;
            }
          }catch(err){
            MostrarAlerta();
            setMessage("Ocurrio un error");
            setStyle("error");
          }finally{
            setIsloading(false);
          }
    };

     //Función para poder navegar a otra vista
        handleNavigate = () => {
            navigate(`/verificarEmail?vista=${vistaParam}-ResetPass`);
        };

    //Función para mostrar la Alerta de validación
    const MostrarAlerta = () => {
        setOpenAlert(true);
        setTimeout(() => {
        setOpenAlert(false);
        }, 3000)
    };

    return (
        <div>
            <Box
                sx={{
                    padding: "15px 30px",
                }}
                display="flex"
                alignItems="center"
            >
                <Box flexGrow={1} height="50%">
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: "32px",
                            fontWeight: "200",
                            mb: 2
                        }}
                    >
                       Recuperar Contraseña
                    </Typography>
                    <Typography
                        variant="h3"
                        sx={{
                        fontSize: "15px",
                        fontWeight: "100",
                        mb: 4
                        }}
                    >
                        Recibe un correo electrónico para poder restablecer la contraseña
                    </Typography>
                </Box>
            </Box>
            <form>
                <TextField
                    id="outlined-basic"
                    label="Ingrese Su Correo Electrónico"
                    variant="outlined"
                    type="email"
                    value={email}
                    error={error}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <GmailIcon />
                        </InputAdornment>,
                    }}
                    color={vistaParam === 'Tunueva.casa' ? 'error':'primary'}
                    sx={{
                        mb: 4,
                    }}
                />
              

               {isLoading ? 
               <Box alignItems="center" display="flex">
                   <Box sx={{paddingLeft: "50%",paddingRight: "50%",}}>
                       <Theme>
                       <CircularProgress color={vistaParam === 'Tunueva.casa' ? 'red':'primary'} />
                       </Theme>
                   
                   </Box>
                
               </Box>
               :
                <Button
                    variant="contained"
                    fullWidth
                    onClick={handleEnviarCodigo}
                    sx={{
                        mb: 2,
                        backgroundColor: vistaParam === 'Tunueva.casa' ? '#FC292B':'',
                        ":hover":{
                            backgroundColor: vistaParam === 'Tunueva.casa' ? '#FC292B':''
                        }
                    }}
                >
                    Enviar correo
                </Button>
               }

                

            </form>

            {openAlert && <Alerta openAlert={openAlert} message={message} style={style} />}
        </div>
    );

};
export default RecuperaPasswordForm;
export function handleNavigate(){};