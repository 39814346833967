import React, { useState, useContext } from "react";
import { AuthContext } from "../../../routes/AuthContext";
import { useNavigate, useLocation, } from "react-router-dom";
import { Box, Typography, TextField, InputAdornment, Link, Button, Grid, CircularProgress } from "@mui/material";
import Alerta from "../../../components/AlertaComponent/Alerta";
import logo from '../../../assets/images/logos/Tunueva.casa.png'
import GmailIcon from "@mui/icons-material/EmailOutlined";
import { Login, createSession } from "../../../API/Login";
import SecureIcon from "@mui/icons-material/LockOutlined";
import DialogLogout from "../../../components/DialogComponent/DialogLogout";
import Theme from "../../../components/Theme/Theme";


const FormLogin = () => {

  const [showPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [style, setStyle] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const vistaParam = queryParams.get('vista');
  //Usamos el contexto login para indicar que estamos autenticados
  const {login, loginAdmin} = useContext(AuthContext);

  //Función para enviar el email
  const handleIniciarSesion = async () => {
    if (email === '') {
      setError(true);
      MostrarAlerta();
      setMessage("Debe completar el campo");
      setStyle("error")
      return;
    }
    if (password === '') {
      setError(true);
      MostrarAlerta();
      setMessage("Debe completar el campo");
      setStyle("error")
      return;
    }
    setError(false);
    setIsloading(true);
    try {
      const {rol, auth, success, messageErr, styleErr, admin } = await Login(email, password, vistaParam);
      if (success) {
        MostrarAlerta();
        setMessage(messageErr);
        setStyle(styleErr);
        if(auth){
            login(email, rol);
        }

        if( vistaParam === 'ADMIN' && admin){
          loginAdmin(email);
        }
        return;
      }
      if(!success){
        setOpenDialog(true);
      }

    } catch (err) {
      MostrarAlerta();
      setMessage('Ocurrion un error');
      setStyle("error");
    } finally {
      setIsloading(false);
    }
    
  };

  
  //Función para poder navegar al inicio
  handleNavigateLogin = () => {
    if(vistaParam === 'Tunueva.casa'){
      navigate("/Tunueva.casa");
    }else if(vistaParam === 'AMIC'){
      navigate("/AMIC");
    }else if(vistaParam === 'VIP'){
      navigate("/VIP");
    }else if(vistaParam === 'ADMIN'){
      navigate("/Admin");
    }
    
  };

  const handleLogout = async () =>{
    try{
      setLoading(true);
      const { auth, messageErr, styleErr } = await createSession(false, email);
      if(auth){
        MostrarAlerta();
        setMessage(messageErr);
        setStyle(styleErr);
        sessionStorage.removeItem('login');
        setLoading(false);
        closeDialog();
        return;
      }else{
        MostrarAlerta();
        setMessage(messageErr);
        setStyle(styleErr);
        setLoading(false);
        closeDialog();
        return;
      }
    }catch(err){

    }

     
  };

  const closeDialog =() =>{
    setOpenDialog(false);
  };

  //Función para mostrar la Alerta de validación
  const MostrarAlerta = () => {
    setOpenAlert(true);
    setTimeout(() => {
      setOpenAlert(false);
    }, 3000)
  };




  return (
    <div>
      {vistaParam ==='Tunueva.casa' ?
       <Box
        sx={{
          padding: "15px 30px",
          mb:4
        }}
        display="flex"
        alignItems="center"
        justifyContent='center'
        >
        <img alt="Tunueva.casa-logo" src={logo}   width="280px"/>
        </Box>
        :
        <Box
          sx={{
            padding: "15px 30px",
            mb:4
          }}
          display="flex"
          alignItems="center"
        >
          <Box flexGrow={1} height="50%">
            <Typography
              variant="h2"
              sx={{
                fontSize: "32px",
                fontWeight: "200",
                mb: 2
              }}
            >
              Bienvenido a RenovArte 
            </Typography>
          </Box>
        </Box>
      }
     
      
      <form >
        <TextField
          id="email"
          label="Correo Electrónico"
          variant="outlined"
          type="email"
          value={email}
          error={error}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          InputProps={{
            startAdornment:
              <div>
                <InputAdornment position="start">
                  <GmailIcon />
                </InputAdornment>
              </div>

          }}
         color={vistaParam === 'Tunueva.casa' ? 'error':'primary'}
          sx={{
            mb: 4,
          }}
          
        />
        <TextField
          id="password"
          label="Contraseña"
          variant="outlined"
          value={password}
          error={error}
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">
              <SecureIcon />
            </InputAdornment>,

          }}
          color= {vistaParam === 'Tunueva.casa' ? 'error':'primary'}
          sx={{
            mb: 1,
          }}
        />
        <Grid  container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              sx={{mb:3}}  
        >
          <Grid item  >
          <Link 
          color={ vistaParam ==='Tunueva.casa' ? "#F6641F":'primary'}
          href={`/RecuperaPassword?vista=${vistaParam}`} 
          underline="hover" 
          variant="body2"  
          
          >
            ¿Olvidaste tu contraseña?
        </Link>
          </Grid>

        </Grid>
        
        
        
       

        {isLoading ?
          <Box alignItems="center" display="flex">
            <Box sx={{ paddingLeft: "50%", paddingRight: "50%", }}>
              <Theme>
                <CircularProgress color={vistaParam === 'Tunueva.casa' ? 'red':'primary'} />
              </Theme>
              
            </Box>
          </Box>
          :
          <Button
            variant="contained"
            fullWidth
            onClick={handleIniciarSesion}
            sx={{
              mb: 2,
              backgroundColor: vistaParam === 'Tunueva.casa' ? '#FC292B' :'',
              ":hover":{
                backgroundColor:vistaParam === 'Tunueva.casa' ? '#C21F22' :''
            },
            }}
          >
            Iniciar Sesión
          </Button>

        }

        {vistaParam ==='Tunueva.casa' ? 
        <Grid container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}>
          <Grid item>
            <Typography
              variant="h3"
              sx={{
                fontSize: "15px",
                fontWeight: "200",
              }}
            >
              Eres nuevo en la plataforma ?
            </Typography>
          </Grid>
          <Grid item>
            
              <Link
                color="#F6641F"
                href="/crearCuenta"
                variant="body2" 
                underline="hover"
              >
                Crear Una Cuenta
              </Link>
             
            
          </Grid>

        </Grid>
          :
          <></>
        }

      </form>
      {openAlert && <Alerta openAlert={openAlert} message={message} style={style} />}
      {openDialog && <DialogLogout open={openDialog} handleClose={closeDialog} loading={loading} handleLogout={handleLogout}/> }

    </div>

  );

};

export default FormLogin;
export function handleNavigateLogin() { };
export function AlertaError() { };


