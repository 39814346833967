import React from "react";
import {Box, Grid, Typography, Container, Card, Stack} from '@mui/material';
import AppBarContent from "../AppBar";
import FooterContent from "../Footer/Footer";
import SlectImg from '../../../../assets/images/card-img/select.png';
import ResultImg from '../../../../assets/images/card-img/result.png';
import PerImg from '../../../../assets/images/card-img/personaliza.png';
import ImagenA1 from '../../../../assets/images/card-img/ImagenA1.png'
import ImagenA2 from '../../../../assets/images/card-img/ImagenA2.png'
import ImagenA3 from '../../../../assets/images/card-img/ImagenA3.png'

const PropuestaRenovarte = () =>{

    return(
        <div>
            <AppBarContent/>
            <Stack 
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                        marginTop:"100px"
                    }}
                
            >
                {/*BLOQUE 1 */}
                <Grid 
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{mb:17}}
                spacing={2}
                >
                    
                    <Grid item >
                        <Container maxWidth="sm"> 
                        <Box>
                            <Typography variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", textAlign:"center"}} >
                            ¿IMAGINAS REDISEÑAR TU ESPACIO SIN MOVER UN SOLO MUEBLE?
                            </Typography>
                            
                            <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"justify"}} >
                            Con RenovArte, es posible. Nuestra 
                            avanzada tecnología de inteligencia artificial te permite visualizar transformaciones 
                            espectaculares de tu propiedad con solo subir una foto. Experimenta el futuro del diseño y la 
                            remodelación sin salir de casa.
                            </Typography>
                        </Box>
                        </Container>
                       
                    </Grid>

                    <Grid item 
                          sx={{
                                display: "flex",
                                alignItems: "stretch",
                            }}>
                        <Card 
                        variant="outlined"
                        sx={{
                            maxWidth:450,
                            p: 0,
                           
                         }}
                        >
                        <img src={ImagenA1}  alt="nevacasa.img" height="100%" width="100%"/>
                        </Card>

                    </Grid>

                </Grid>
             {/*BLOQUE 2 */}
                <Typography variant="h1" gutterBottom sx={{mb:9, fontWeight:"bold", textAlign:"center"}} >
                    CREA, EXPERIMENTA, TRANSFORMA
                </Typography>

                <Grid 
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{mb:17}}
                    spacing={1}
                >
                    <Grid item>
                    <Container maxWidth="sm"> 
                        <Box maxWidth={350}>
                            <Typography variant="h3" gutterBottom  color="gray" sx={{mb:2}} >
                            SUBE UNA FOTO
                            </Typography>

                            <Card
                            variant="outlined"
                            sx={{
                                maxWidth:350,
                                height:250,
                                p: 0,
                                mb:2,
                             }}
                            >
                            <img src={SlectImg}  alt="nevacasa.img" height="100%" width="100%"/>
                            </Card>
                            
                            <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"justify", mb:4}}>
                            Captura el espacio que deseas transformar y súbela a nuestra plataforma.
                            </Typography>
                        </Box>
                        </Container>
                    </Grid>

                    <Grid 
                     item
                    >
                     <Container maxWidth="sm"> 
                        <Box maxWidth={350}>
                            <Typography variant="h3" gutterBottom  color="gray" sx={{mb:2}} >
                                PERZONALIZA A TU GUSTO
                            </Typography>
                            <Card
                            variant="outlined"
                            sx={{
                                maxWidth:350,
                                height:250,
                                p: 0,
                                mb:2,
                               
                             }}
                            >
                            <img src={PerImg}  alt="nevacasa.img" height="100%" width="100%"/>
                            </Card>
                            <Typography  variant="subtitle1" gutterBottom  sx={{textAlign:"justify", mb:4}}>
                                Elige entre múltiples estilos y acabados. ¡Tú decides el futuro de tu espacio!
                            </Typography>
                        </Box>
                        </Container>
                    </Grid>

                    <Grid 
                        item
                    >
                    <Container maxWidth="sm">
                        <Box maxWidth={350}>
                            <Typography variant="h3" gutterBottom  color="gray"sx={{mb:2}} >
                            VIZUALIZA CAMBIOS
                            </Typography>
                            <Card
                            variant="outlined"
                            sx={{
                                maxWidth:350,
                                height:250,
                                p: 0,
                                mb:2,

                             }}
                            >
                            <img src={ResultImg}  alt="nevacasa.img" height="100%" width="100%"/>
                            </Card>
                            <Typography  variant="subtitle1" gutterBottom  sx={{textAlign:"justify"}}>
                            Nuestro algoritmo generativo te mostrará opciones de diseño 
                            impresionantes en segundos.
                            </Typography>
                        </Box>
                        </Container>
                     </Grid>

                </Grid>

                {/*BLOQUE 3 */}
                <Typography variant="h1" gutterBottom sx={{mb:5, fontWeight:"bold", textAlign:"center" }} >
                    CONECTA CON EXPERTOS Y RECURSOS
                </Typography>

                <Grid 
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{mb:17}}
                spacing={2}
                >
                  
                    <Grid 
                        item 
                        sx={{
                            display: "flex",
                            alignItems: "stretch",
                        }}
                    >
                    <Card 
                        variant="outlined"
                        sx={{
                            maxWidth:450,
                            p: 0,
                         }}
                        >
                        <img src={ImagenA2}  alt="nevacasa.img" height="100%" width="100%"/>
                        </Card>
                        
                    </Grid>

                    <Grid item>
                        
                    <Container maxWidth="sm"> 
                        <Box>
                            <Typography  variant="subtitle1" gutterBottom  sx={{textAlign:"justify"}}>
                            No solo te ayudamos a visualizar, sino que conectamos tus ideas con expertos. A través de 
                            nuestra asociación con AMIC y nuestros vínculos con la industria, te ofrecemos acceso a 
                            proveedores certificados y a las mejores opciones del mercado para hacer realidad tus proyectos.
                            </Typography>
                        </Box>
                        </Container>
                    </Grid>

                </Grid>

                 {/*BLOQUE 4 */}
                 <Typography variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", textAlign:"center" }} >
                 PARTICIPA Y GANA
                </Typography>
                
                <Container maxWidth="sm" sx={{mb:17}}>
                    <Box maxWidth="100%">
                    <Typography  variant="subtitle1" gutterBottom  sx={{textAlign:"center"}}>
                        Únete a nuestros concursos y retos. Demuestra tus habilidades de diseño, comparte tus 
                        creaciones y gana premios que te ayudarán a realizar tus proyectos de renovación.
                    </Typography>
                    </Box>

                </Container>

                {/*BLOQUE 5 */}
                <Grid 
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{mb:17}}
                spacing={2}
                >
                    
                    <Grid item >
                        <Container maxWidth="sm"> 
                        <Box>
                            <Typography variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", textAlign:"center"}} >
                            Únete A La Comunidad RenovArte
                            </Typography>
                            
                            <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"justify"}} >
                            En RenovArte, creemos en el poder de la comunidad. Conéctate con otros entusiastas del diseño, 
                            intercambia ideas y encuentra inspiración. Juntos, podemos transformar los espacios de vida de 
                            formas nunca antes vistas.
                            </Typography>
                        </Box>
                        </Container>
                       
                    </Grid>

                    <Grid item 
                          sx={{
                                display: "flex",
                                alignItems: "stretch",
                            }}>
                        <Card 
                        variant="outlined"
                        sx={{
                            maxWidth:450,
                            p: 0,
                           
                         }}
                        >
                        <img src={ImagenA3}  alt="nevacasa.img" height="100%" width="100%"/>
                        </Card>

                    </Grid>

                </Grid>
                
            </Stack>
            <FooterContent/>
            
        </div>
    );

};
export default PropuestaRenovarte;