import React, { useState } from "react";
import { Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, InputAdornment } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Alerta from "../../../components/AlertaComponent/Alerta";
import UserIcon from "@mui/icons-material/Person2Outlined";
import GmailIcon from "@mui/icons-material/EmailOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SecureIcon from "@mui/icons-material/LockOutlined";
import { InsertarUsuario } from "../../../API/Admin";
import { handleconsultarUsuarios } from "./ExTable";
import TipoUsuarioData from '../../../Model/TipoUsuarioData';

const DialogFormAddUser = (props) => {
    const [error, setError] = useState(false);
    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [telefono, setTelefono] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword] = useState(false);
    const [minLienght, setMinLeght] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasUppercase, setHasUpperCase] = useState(false);
    const [userType, setUserType] = useState(null);
    const [rol, setRol] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [style, setStyle] = useState("");
    const [loading, setLoading] = useState(false);
    const tipoUsuarioData = TipoUsuarioData.map((data) => data);

    //Función para poder insetar los datos
    const handleAddUser = async () => {
        if (nombre === '') {
            setError(true);
            MostrarAlerta();
            setMessage("Debe completar el campo");
            setStyle("error");
            return;
        }
        if (email === '') {
            setError(true);
            MostrarAlerta();
            setMessage("Debe completar el campo");
            setStyle("error");
            return;
        }
        if (telefono === '') {
            setError(true);
            MostrarAlerta();
            setMessage("Debe completar el campo");
            setStyle("error");
            return;
        }
        if (telefono.length < 10 || telefono.length > 10) {
            setError(true);
            MostrarAlerta();
            setMessage("Ingrese un número de 10 digitos");
            setStyle("error");
            return;
        }
        if (password === '') {
            setError(true);
            MostrarAlerta();
            setMessage("Debe completar el campo");
            setStyle("error");
            return;
        }
        if (!isValidPasswordMinLenght(password)) {
            setError(true);
            MostrarAlerta();
            setMessage("La contraseña mínimo debe de contar con 8 caracteres");
            setStyle("error");
            return;
        }
        if (!isValidPasswordHasNumber(password)) {
            setError(true);
            MostrarAlerta();
            setMessage("La contraseña mínimo debe de contar con 1 número");
            setStyle("error");
            return;
        }
        if (!isValidPasswordHasUpperCase(password)) {
            setError(true);
            MostrarAlerta();
            setMessage("La contraseña mínimo debe de contar con al menos una letra mayúscula");
            setStyle("error");
            return;
        }
        if(userType === ''){
            setError(true);
            MostrarAlerta();
            setMessage("Debe completar el campo");
            setStyle("error");
            return;
        }
        if (rol === null) {
            setError(true);
            MostrarAlerta();
            setMessage("Debe completar el campo");
            setStyle("error");
            return;
        }
        setError(false);
        try {
            setLoading(true);
            const { insert, success, messageErr, styleErr } = await InsertarUsuario(nombre, email, telefono, userType ? userType.tipousuario : null, password, rol ? rol.value : null);
            if (success) {
                MostrarAlerta();
                setMessage(messageErr);
                setStyle(styleErr);
                if (insert) {
                    setTimeout(() => {
                        props.handleClose();
                    }, 1000)
                    handleconsultarUsuarios();
                    return;
                }
                return;
            }
        } catch (err) {
            setError(true);
            MostrarAlerta();
            setMessage("Ocurrio un erro al insertar los datos");
            setStyle("error")
        } finally {
            setLoading(false);
        }

    };

    //Función para poder tener una contraseña segura
    const handleChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        setMinLeght(newPassword.length >= 8);
        setHasNumber(/\d/.test(newPassword));
        setHasUpperCase(/[A-Z]/.test(newPassword));

    };

    //Validamos que el password cumpla con lo solicitado
    const isValidPasswordMinLenght = (password) => {
        return minLienght;

    };
    const isValidPasswordHasNumber = (password) => {
        return hasNumber;

    };
    const isValidPasswordHasUpperCase = (password) => {
        return hasUppercase;

    };

    const updateRol = (value) => {
        setRol(value);
    };

    const updateUserType = (value) =>{
        setUserType(value);
    };
    const Rol = [
        {
            rol: "Tunueva.casa",
            value: 1
        },
        {
            rol: "AMIC",
            value: 2
        },
        {
            rol: "VIP",
            value: 3
        },
        {
            rol: "ADMIN",
            value: 4
        }
    ];

    //Función para mostrar la Alerta de validación
    const MostrarAlerta = () => {
        setOpenAlert(true);
        setTimeout(() => {
            setOpenAlert(false);
        }, 3000)
    };

    return (
        <div>
            <Dialog
                sx={{
                    margin: 'auto',
                }}
                open={props.open}
                onClose={props.handleClose}
                PaperProps={{
                    component: 'form'
                }}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>Añadir Un Nuevo Usuario</DialogTitle>
                <DialogContent sx={{ scroll: "body" }}>
                        <TextField
                            id="default-value"
                            label="Nombre de usuario"
                            variant="outlined"
                            margin="dense"
                            value={nombre}
                            error={error}
                            onChange={(e) => setNombre(e.target.value)}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <UserIcon />
                                </InputAdornment>,

                            }}
                            sx={{
                                mb: 2,
                            }}
                        />
                        <TextField
                            id="email"
                            label="Correo Electrónico"
                            variant="outlined"
                            type="email"
                            value={email}
                            error={error}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            InputProps={{
                                startAdornment:
                                    <div>
                                        <InputAdornment position="start">
                                            <GmailIcon />
                                        </InputAdornment>
                                    </div>

                            }}
                            sx={{
                                mb: 2,
                            }}
                        />

                        <TextField
                            id="outlined-basic"
                            label="Ingrese su Número de WhatsApp"
                            variant="outlined"
                            type="phone"
                            value={telefono}
                            error={error}
                            onChange={(e) => setTelefono(e.target.value)}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <WhatsAppIcon />
                                </InputAdornment>,
                            }}
                            sx={{
                                mb: 2,
                            }}
                        />

                        <TextField
                            id="password"
                            label="Contraseña"
                            variant="outlined"
                            value={password}
                            error={error}
                            onChange={handleChange}
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <SecureIcon />
                                </InputAdornment>,

                            }}
                            sx={{
                                mb: 2,
                            }}
                        />
                        <Autocomplete
                            fullWidth
                            size="medium"
                            value={userType}
                            onChange={(event, newValue) => {
                                setUserType(newValue);
                                updateUserType(newValue);
                            }}
                            id="controllable-states-demo"
                            options={tipoUsuarioData}
                            getOptionLabel={(option) => option.tipousuario}
                            sx={{ margin: "auto", mb:2 }}
                            renderInput={(params) =>
                                <TextField {...params} error={error} label="Tipo de Usuario" fullWidth />
                            }
                           
                        />

                        <Autocomplete
                            fullWidth
                            size="medium"
                            value={rol}
                            onChange={(event, newValue) => {
                                setRol(newValue);
                                updateRol(newValue);
                            }}
                            id="controllable-states-demo"
                            options={Rol}
                            getOptionLabel={(option) => option.rol}
                            sx={{ margin: "auto" }}
                            renderInput={(params) =>
                                <TextField {...params} error={error} label="Rol" fullWidth />
                            }
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                        />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose
                    }>Cancelar</Button>
                    <LoadingButton loading={loading} loadingIndicator="Añadiendo" onClick={handleAddUser}>Añadir</LoadingButton>

                </DialogActions>
            </Dialog>
            {openAlert && <Alerta openAlert={openAlert} message={message} style={style} />}
        </div>
    );

}
export default DialogFormAddUser;