const ColorPredominante =[
    {
        categoria: "Black & White",
        colores: [
            {color:'Blanco'},
            {color:'Negro'},
        ]
    },
    {
        categoria: "Bold accents",
        colores: [
            {color:'Amarillo mostaza'},
            {color:'Fucsia '},
            {color:'Rojo intenso'},
            {color:'Turquesa vibrante'},
            {color:'Verde esmeralda'},
        ]
    },
    {
        categoria: "Cálidos",
        colores: [
            {color:'Amarillo'},
            {color:'Naranja'},
            {color:'Rojo'},
        ]
    },
    {
        categoria: "Cool greys",
        colores: [
            {color:'Azul acero '},
            {color:'Gris carbón'},
            {color:'Gris plomo'},
            {color:'Gris pizarra'},
            {color:'Taupe'},
        ]
    },
    {
        categoria: "Crisp White",
        colores: [
            {color:'Blanco inmaculado'},
            {color:'Blanco hueso'},
            {color:'Blanco tiza'},
            {color:'Blanco nieve'},
            {color:'Blanco algodon'},
            {color:'Blanco roto'},
            {color:'Blanco seda'},
        ]
    },
    {
        categoria: "Deep blues",
        colores: [
            {color:'Azul marino'},
            {color:'Azul cobalto'},
            {color:'Azul real'},
            {color:'Azul zafiro'},
            {color:'Verde azulado'},
        ]
    },
    {
        categoria: "Desert tones",
        colores: [
            {color:'Acromático minimalista'},
            {color:'Blanco y negro minimalista'},
        ]
    },
    {
        categoria: "Earthy neutrals",
        colores: [
            {color:'Beige'},
            {color:'Gris'},
            {color:'Marrón'},
            {color:'Naranja quemado'},
            {color:'Ocre'},
            {color:'Terracota'},   
        ]
    },
    {
        categoria: "Friós",
        colores: [
            {color:'Azul'},
            {color:'Morado'},
            {color:'Verde'},
        ]
    },
    {
        categoria: "Industria texture",
        colores: [
            {color:'Azul cobalto'},
            {color:'Azul petróleo'},
            {color:'Beige industrial'},
            {color:'Cobre envejecido'},
            {color:'Gris cemento'},
            {color:'Marrón ladrillo'},
            {color:'Naranja construcción'},   
        ]
    },
    {
        categoria: "Jawel tones",
        colores: [
            {color:'Amatista'},
            {color:'Azul zafiro '},
            {color:'Rubí'},
            {color:'Topacio'},
            {color:'Turmalina '},
            {color:'Verde esmeralda'},
        ]
    },
    {
        categoria: "Metallic highlights ",
        colores: [
            {color:'Bronce'},
            {color:'Cobre'},
            {color:'Oro'},
            {color:'Oro rosa'},
            {color:'Plata'},
        ]
    },
    {
        categoria: "Monochromatic pastels",
        colores: [
            {color:'Lavanda'},
            {color:'Lila '},
            {color:'Melocotón'},
            {color:'Rosa cuarzo'},
            {color:'Verde menta'},
        ]
    },
    {
        categoria: "Natural greens",
        colores: [
            {color:'Verde bosque'},
            {color:'Verde salvia'},
            {color:'Verde espuma de mar'},
            {color:'Verde lima'},
            {color:'Verde esmeralda'},
        ]
    },
    {
        categoria: "Neutros",
        colores: [
            {color:'Blanco'},
            {color:'Beige'},
            {color:'Gris'},
        ]
    },
    {
        categoria: "Ocean blues",
        colores: [
            {color:'Aguamarina'},
            {color:'Azul cielo'},
            {color:'Azul marino'},
            {color:'Azul cerúleo'},
            {color:'Azul cobalto'},
            {color:'Azul indigo'},
            {color:'Azul petróleo'},
            {color:'Azul teal'},
            {color:'Turquesa'},
            {color:'Verde agua'},
        ]
    },
    {
        categoria: "Pastel calm",
        colores: [
            {color:'Aguamarina'},
            {color:'Beige'},
            {color:'Celeste'},
            {color:'Lavanda'},
            {color:'Lila'},
            {color:'Melocotón '},
            {color:'Rosa cuarzo'},
            {color:'Salvia'},
            {color:'Verde menta'},   
        ]
    },
    {
        categoria: "Scandinavian whites",
        colores: [
            {color:'Blanco inmaculado'},
            {color:'Blanco hueso'},
            {color:'Blanco lino'},
            {color:'Beige greige'},
            {color:'Gris paloma'}, 
        ]
    },
    {
        categoria: "Sunset Hues",
        colores: [
            {color:'Azul índigo'},
            {color:'Dorado'},
            {color:'Lila'},
            {color:'Naranja quemado'},
            {color:'Naranja rojizo'},
            {color:'Rosa melocotón '},
            {color:'Rosa flamenco'},
            {color:'Violeta lavanda'}, 
        ]
    },
    {
        categoria: "Vibrantes",
        colores: [
            {color:'Dinamismo'},
            {color:'Patrones de energia'},
            {color:'Tonos vivos'},   
        ]
    },  
    {
        categoria: "Vibrant boho",
        colores: [
            {color:'Amarillo azafrán'},
            {color:'Fucsia'},
            {color:'Naranja mandarina'},
            {color:'Rosa mexicano'},
            {color:'Terracota tostado'},
            {color:'Turquesa vibrante'},
            {color:'Verde esmeralda'},
            {color:'Violeta ciruela'},   
        ]
    },
    {
        categoria: "Warm woods",
        colores: [
            {color:'Arena beige'},
            {color:'Caramelo '},
            {color:'Crema'},
            {color:'Marfil '},
            {color:'Marrón nuez'},
            {color:'Marrón madera'},
            {color:'Miel '},
            {color:'Naranja quemado'},
            {color:'Rojo teja'},
            {color:'Verde oliva'},
        ]
    },
];
export default ColorPredominante;