import {SERVER_API_URL} from '../rules';
import TNCKLOGO from "../assets/images/LogoWhitemark/TNCK.png";
import TNCCLOGO from "../assets/images/LogoWhitemark/TNCC.png";

export async function MaxResolutionEmail(email, imgURL){
    const API_KEY =process.env.REACT_APP_API_KEY;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
    "key": API_KEY,
    "url": imgURL,
    "scale": 3,
    "webhook": null,
    "face_enhance": false
    });

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };
    try{
        const response = await fetch("https://stablediffusionapi.com/api/v5/super_resolution", requestOptions);
        if(!response.ok){
            throw new Error(`API request failed with status': ${response.status}`);
        }
        const result = await response.json();
        if(result.status === "error"){
            return {send:false, success: true, messageErr:result.messege, style:"error"};
        }
        if(result.status === 'success'){
            const {send, success, messageErr, style,} = await ShareImgEmail(email, result.output);

            if(!send){
                const maxRetries = 3;
                const delay = 12000;
              for(let retries = 0; retries < maxRetries; retries++){
                 const {send, success, messageErr, style} = await ShareImgEmail(email, result.output);
                 if(send){
                     return {send:send, success:success, messageErr:messageErr, style:style};
                 }else if(!send){
                     
                     if (retries < maxRetries - 1) {
                        //console.log(`Enviando el correo success. intentos ${retries + 1}/${maxRetries}. Retrying in ${delay / 1000} seconds...`);
                         await new Promise(resolve => setTimeout(resolve, delay));
                     }else{
                        return {send:false, success:true, messageErr:'Ocurrio un error al intentar enviar el correo', style:'error'}; 
                     }
                 }else{
                     return {send:send, success:success, messageErr:messageErr, style:style};
                 }
 
              }
            }

            return {send: send, success: success, messageErr:messageErr, style:style};

        }else if(result.status === 'processing'){
           const {imgUrl, message, maxresolution} = await FutureLinks(result.fetch_result);

           if(maxresolution){

            const {send, success, messageErr, style} = await ShareImgEmail(email, imgUrl.output);

            if(!send){
                const maxRetries = 3;
                const delay = 12000;
                for(let retries = 0; retries < maxRetries; retries ++){
                    const {send, success, messageErr, style} = await ShareImgEmail(email, imgUrl.output);
                    if(send){
                    return {send:send, success:success, messageErr:messageErr, style:style};
                    }else if(!send){
                        //console.log(`Enviando el correo. intentos ${retries + 1}/${maxRetries}. Retrying in ${delay / 1000} seconds...`);
                        if (retries < maxRetries - 1) {
                            await new Promise(resolve => setTimeout(resolve, delay));
                        }else{
                            return {send:false, success:true, messageErr:'Ocurrio un error al intentar enviar el correo', style:'error'};
                        }
                    }else{
                        return {send:send, success:success, messageErr:messageErr, style:style};
                    }

                }
            }
           
            return {send: send, success: success, messageErr:messageErr, style:style};

           }

           return {send: false, success: true, messageErr:message, style:'error'};

           
        }
         
    }catch(err){
        return "Ocurrion un error";
    }


};

async function ShareImgEmail (email, ImgURL){
        try{
            const imageBlob = await addWatermarkToImage(ImgURL);
            
            const formData = new FormData();
            formData.append('image', imageBlob, 'RenovArte.png');
            formData.append('correo', email);
    
              var requestOptions = {
                method: 'POST',
                body: formData,
                };
    
            const response =  await fetch(`${SERVER_API_URL}/api/shareImg`, requestOptions);
            if(!response.ok){
                const result = await response.json();
                return {send: false,success: true, messageErr: result.message, style:"error"};
            }
            const result = await response.json();
            return {send: true, success: true, messageErr: result.message, style:"success"};
    
        }catch(err){
            return "Ocurrion un error al intentar enviar la imagen"
        } 
};

async function FutureLinks(edpoitn, maxRetries = 13, delay = 12000) {
    const API_KEY = process.env.REACT_APP_API_KEY;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
  
    var raw = JSON.stringify({
        "key": API_KEY,
    });
  
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };
    for(let retries = 0; retries < maxRetries; retries++){
        
            const response = await fetch(edpoitn, requestOptions);
            const result = await response.json();
  
            if (result.status === "success") {
                //console.log("Imagen con respuesta en success", result);
                return {imgUrl:result, message:'', maxresolution:true};
            } else if(result.status === "processing"){
              //console.log('result:', result);
                //console.log(`Subiendo la resolución. Intentos ${retries + 1}/${maxRetries}. Retrying in ${delay / 1000} seconds...`);
                if (retries < maxRetries - 1) {
                  await new Promise(resolve => setTimeout(resolve, delay));
              }else{
                  return{ imgUrl: '', message:'No se puedo obtener la iamgen con alta resolución', maxresolution:false};
              }
                //console.log(result);
            }else{
                throw new Error(`API returned unexpected status: ${result.status}`)
            }
           
        }
        
        throw new Error("Max retries reached. The image generation is taking too long");
    }
    
    async function addWatermarkToImage(imagUrl){
        return new Promise((resolve, reject) =>{
            const img = new Image();
            img.crossOrigin ='Anonymous';
            img.onload = async function(){
                //Creamos un elemento canvas
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                //Establecemos el tamaño del canvas (igual al tamaño de al imagen original)
                canvas.width = img.width;
                canvas.height = img.height;

                //Dibujamos la imagen original en el canvas
                ctx.drawImage(img, 0, 0);

                //Función para cargar una imagen
                async function loadImage(url){
                    const response = await fetch(url);
                    if(!response.ok){
                        throw new Error(`Faild to fetch image: ${url}`);
                    }
                    const blob = await response.blob();
                    const imageUrl = URL.createObjectURL(blob);
                    return new Promise((resolve) =>{
                        const img = new Image();
                        img.onload = () => resolve(img);
                        img.src = imageUrl;
                    });
                }
                //Cargamos las tres imagenes de marca de agua
                const [centerLogo, leftLogo, rightLogo] = await Promise.all([
                    loadImage(TNCKLOGO),
                    loadImage(TNCCLOGO),
                    loadImage(TNCCLOGO)
                ]);

                //Función para dibujar una marca de agua
                function drawWatermark(image, x, y, width, height, opacity = 0.5) {
                    ctx.globalAlpha = opacity;
                    ctx.drawImage(image, x, y, width, height);
                    ctx.globalAlpha = 1.0;
                  }
                
                // Marca de agua central inferior
                const centerWidth = canvas.width * 0.5; //50% del ancho de la imagen
                const centerHeight = (centerLogo.height / centerLogo.width) * centerWidth; //Mantiene la proporción
                const centerX = (canvas.width - centerWidth) / 2; //Centrado horizontal
                const centerY = canvas.height - centerHeight - 10; // 10px de margen inferior
                drawWatermark(centerLogo, centerX, centerY, centerWidth, centerHeight);

                // Marca de agua inferior izquierda
                const sideWidth = canvas.width * 0.1;  // 10% del ancho de la imagen 
                const sideHeight = (leftLogo.height / leftLogo.width) * sideWidth; //Mantiene la posición
                const leftX = canvas.width * 0.05; // left: 0.5%
                const leftY = canvas.height * 0.2 - sideHeight; //top 20 %
                drawWatermark(leftLogo, leftX, leftY, sideWidth, sideHeight);

                // Marca de agua inferior derecha
                const rightX = canvas.width * 0.95 - sideWidth;//right:95%
                const rightY = canvas.height * 0.2 - sideHeight;//top: 20% 
                drawWatermark(rightLogo, rightX, rightY, sideWidth, sideHeight);

                
                canvas.toBlob((blob)=>{
                    resolve(blob);
                }, 'image/png');
            };
            img.onerror = reject;
            img.src = imagUrl;
        });
        
    }