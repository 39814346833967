import imageCompression from 'browser-image-compression';

async function ImageCompressor(file) {
  const MAX_WIDTH = 680;
  const MAX_HEIGHT = 740;

  // Función para obtener las dimensiones de la imagen
  const getImageDimensions = (file) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.src = URL.createObjectURL(file);
    });
  };

  // Función para redimensionar la imagen
  const resizeImage = async (imageFile) => {
    const { width: originalWidth, height: originalHeight } = await getImageDimensions(imageFile);
    //console.log(`Dimensiones originales: ${originalWidth}x${originalHeight}`);

    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        let width = img.width;
        let height = img.height;
        
        if (width > MAX_WIDTH || height > MAX_HEIGHT) {
          if (width / height > MAX_WIDTH / MAX_HEIGHT) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          } else {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        //console.log(`Dimensiones después del redimensionamiento: ${width}x${height}`);

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        
        canvas.toBlob(resolve, imageFile.type);
      };
      img.src = URL.createObjectURL(imageFile);
    });
  };

  // Primero, redimensionamos si es necesario
  let processedFile = await resizeImage(file);

  // Si el archivo es menor a 100 KB después del redimensionamiento, lo devolvemos
  if (processedFile.size <= 100 * 1024) {
    //console.log("Imagen menor a 100 KB, no se comprime más");
    return processedFile;
  }

  // Si aún es mayor a 100 KB, procedemos con la compresión
  const options = {
    maxSizeMB: 0.2, // Máximo 200 KB
    maxWidthOrHeight: Math.max(MAX_WIDTH, MAX_HEIGHT), // Aseguramos que no exceda nuestras dimensiones máximas
    useWebWorker: true,
    initialQuality: 0.5,
  };

  try {
    let compressedFile = await imageCompression(processedFile, options);
    
    // Si el archivo es aún mayor de 200 KB, intentamos comprimir más
    if (compressedFile.size > 200 * 1024) {
      options.initialQuality = 0.3;
      compressedFile = await imageCompression(compressedFile, options);
    }
    
    const finalDimensions = await getImageDimensions(compressedFile);
    //console.log(`Dimensiones finales después de la compresión: ${finalDimensions.width}x${finalDimensions.height}`);
    
    return compressedFile;
  } catch (error) {
    console.error('Error al procesar la imagen:', error);
    return null;
  }
}

export default ImageCompressor;