import React, {useState} from "react";
import {TextField, Autocomplete, Grid} from '@mui/material';
import TipoFotografia from "../../../../Model/TipoFotografiaData";
import {updateTipo, updateChangeArea} from './PrompComplete';


const AutocompleteTipoFotografia = () =>{
    const [tipoFotografia, setTipoFotografia] = useState(null);
    const [changeObjeto, setChangeObjeto] = useState(null);
    
      //Variable que recorre la lista de Predupuesto
  const DataTipoFotografia = TipoFotografia.map((data)=>data);
  const DataAreaChange = TipoFotografia[2].area.map((item) => item);

  const handleChange = (event, newValue)=>{
      if(newValue){
        setTipoFotografia(newValue.tipoFotografia);
        setChangeObjeto(null);
        updateTipo(newValue.traduction);
      }else{
        setTipoFotografia(null);
        updateTipo("");
      }
  }
    return (
        <div>
            <Grid container spacing ={1}>
                <Grid item>
                <Autocomplete
                    size="small"
                    //value={tipoFotografia}
                    onChange={handleChange}
                    id="controllable-states-demo"
                    options={DataTipoFotografia}
                    getOptionLabel={(option)=> option.tipoFotografia}
                    sx={{ width: 225,   margin:"auto" }}
                    renderInput={(params) => <TextField {...params} label="Tipo" />}
                />
                </Grid>

                <Grid item>
                    {
                        tipoFotografia === "Cambio de color" || tipoFotografia === "Mejorar y sustituir"  ?
                        <Autocomplete
                        size="small"
                        onChange={(event, newValue) => {
                            setChangeObjeto(newValue);
                            if(newValue){
                                updateChangeArea(newValue.traduction);
                            }else{
                                updateChangeArea("");
                            }
                           
                        }}
                        id="changeArea"
                        options={DataAreaChange}
                        getOptionLabel={(option)=> option.objeto}
                        sx={{ width: 225,   margin:"auto" }}
                        renderInput={(params) => <TextField {...params} label="Sección de cambio" />}
                       
                        />
                        : null
                    }
                </Grid>

            </Grid>
          
        </div>
);

}
export default AutocompleteTipoFotografia;