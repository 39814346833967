import  React, {useState} from 'react';
import {TextField, Autocomplete} from '@mui/material';
import Presupuesto from '../../../../Model/PresupuestoData';
import { updatePresupuesto } from './PrompComplete';


export default function  AutoCompletPresupuesto() {
  const [presupuesto, setPresupuesto] = useState(null);
 
  //Variable que recorre la lista de Predupuesto
  const DataPresupuesto = Presupuesto.map((data)=>data);
 
  return (
    <div>
            <Autocomplete
                size="small"
                //value={presupuesto}
                onChange={(event, newValue) => {
                  if(newValue){
                    setPresupuesto(newValue.presupuesto);
                    updatePresupuesto(newValue.traduction);
                  }else{
                    setPresupuesto(null);
                    updatePresupuesto("");
                  }
                }}
                id="controllable-states-demo"
                options={DataPresupuesto}
                getOptionLabel={(option)=> option.presupuesto}
                sx={{ width: 225, margin:"auto" }}
                renderInput={(params) => <TextField {...params} label="Presupuesto" />}
            />  
            
    </div>
  );
}