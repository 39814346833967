import React from 'react';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { handleShowSpinner, handleShowSpinnerStop } from '../../views/PaginaInicio/PaginaInicio';
import { handleShowSpinnerArqui, handleShowSpinnerStopArqui } from '../../views/PaginaInicioArqui/PaginaInicioArqui';
import { handleShowSpinnerVip, handleShowSpinnerStopVip } from '../../views/PaginainicioVIP/PaginaInicioVIP';
import {eliminarRegistroPorId} from '../../Model/FuncionAlmacenar';

const DeleteButton = ({ idAEliminar }) => {

    const handleDelete = () => {
      const resultadoEliminacion = eliminarRegistroPorId(idAEliminar);
          
        if (resultadoEliminacion) {
            handleShowSpinner();
            handleShowSpinnerArqui();
            handleShowSpinnerVip();
            setTimeout(() => {
              handleShowSpinnerStop();
              handleShowSpinnerStopArqui();
              handleShowSpinnerStopVip();
            }, 2000);
          } else {
            
          }

    };

  return (
    <IconButton color="error" fontSize="inherit"  onClick={handleDelete} aria-label="delete">
      <DeleteIcon />
    </IconButton>
  );
};

export default DeleteButton;