import React, {useState} from "react";
//import MessageCard  from "./paginainicio-components/MessageCard";
//import ImagenRespuesta  from "./paginainicio-components/ImagenRespuesta";
import UsuarioMessage from "./paginainicio-components/UsuarioMessage";
import Spinner from "../Spinner/Spinner";
import FullLayout from "../../layouts/FullLayout/FullLayout";
import PrompComplete, {hanldeCleanPromp} from './paginainicio-components/Autocomplet-Components/PrompComplete';

const PaginaInicio =() => {
    const [showSpinner, setShowSpinner] = useState(false);

    handleShowSpinner = () =>{
        setShowSpinner(true);
    };
    handleShowSpinnerStop = () =>{
        setShowSpinner(false);
    };
   
    return (
        <div>
        <FullLayout>
        {!showSpinner && <UsuarioMessage/>}
          {showSpinner &&<Spinner/> }
          
        {!showSpinner && <PrompComplete/>}
        </FullLayout>
         
        </div>
        
    );
}
export default PaginaInicio;
export function handleShowSpinner() { };
export function handleShowSpinnerStop() { };
