import React, {useContext, useState, useEffect} from "react";
import { AuthContext } from "../../../routes/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
//import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
//import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import {handleShowSpinner, handleShowSpinnerStop} from '../../../views/PaginaInicio/PaginaInicio';
import { handleShowSpinnerArqui, handleShowSpinnerStopArqui } from "../../../views/PaginaInicioArqui/PaginaInicioArqui";
import { handleShowSpinnerVip, handleShowSpinnerStopVip } from "../../../views/PaginainicioVIP/PaginaInicioVIP";
import LogoIcon from '../Logo/LogoIcon';

import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  Button,
  Avatar,
  Divider,
  ListItemIcon,
  Typography,
  Grid
} from "@mui/material";

import TNCLOGO from "../../../assets/images/users/TNC.png";
import user from "../../../assets/images/users/3.jpg";
import {ConsultarPerfil} from '../../../API/Perfil';

const Header = (props) => {
  const [nombre, setNombre] = useState("");
  const [consult, setConsult] = useState("");
  const [rol, setRol] = useState("");
  const email  = sessionStorage.getItem('correo');

  useEffect(()=> {
    ConsultarUsuario();
},[]);

//Función para poder consultar el nombre de perfil de cada usuario y el # de consultas al API roomInterior
  const ConsultarUsuario = async()=>{
    try{  
      const {usuario, success} = await ConsultarPerfil(email);
      if(!success){
          setNombre(usuario.name);
          setConsult(usuario.consult);
          setRol(usuario.rol);
          sessionStorage.setItem('userRole', JSON.stringify(usuario.rol));
          return;
        }else{
          setNombre("");
          setConsult("0");
          setRol("");
        }
  }catch(err){
      return;
  }

  };

  //Función que permite actualizar el # de consultas
  UpdateCountAPI =(value) =>{
    setConsult(value);
};
  
  const navigate = useNavigate();
  const {logout, logoutAdmin} = useContext(AuthContext);
  const [anchorEl4, setAnchorEl4] = React.useState(null);

  const location = useLocation();
  const currentView = location.pathname.split('/')[1];

 

  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };

  const handleClose4 = () => {
    setAnchorEl4(null);
  };
  const handleLogout = ()=>{
    handleShowSpinner();
    handleShowSpinnerArqui();
    handleShowSpinnerVip();
    setTimeout(() => {
      handleShowSpinnerStop();
      handleShowSpinnerStopArqui();
      handleShowSpinnerStopVip();
      if(currentView ==='Admin'){
        logoutAdmin();
        sessionStorage.removeItem('correo');
      }else{
        logout();
        sessionStorage.removeItem('correo');
      }
    }, 2000);
    
  };

  const  navigatePerfil = () =>{
    if(currentView === 'Tunueva.casa'){
      navigate('/Tunueva.casa/perfil');
      handleClose4();
    }else if(currentView === 'AMIC'){
      navigate('/AMIC/perfil');
      handleClose4();
    }else if(currentView === 'VIP'){
      navigate('/VIP/perfil');
      handleClose4();
    }
    

  };

 
    const getColor = (value) => {
      if (value <= 18) return 'success.main';
      if (value <= 35) return 'warning.main';
      return 'error.main';
    };
  


  return (
    <AppBar sx={props.sx} elevation={0} className={props.customClass}>
      <Toolbar>
        {currentView === 'Admin' ?
        <></>
        :
        <IconButton
          color="inherit"
          aria-label="menu"
          onClick={props.toggleMobileSidebar}
          sx={{
            display: {
              lg: "none",
              xs: "inline",
            },
          }}
        >
          <MenuOutlinedIcon width="20" height="20" />
        </IconButton>
        }

          {currentView === 'Admin' ?
          <Box sx={{ display: "flex", alignItems: "Center" }}>
            <LogoIcon />
          </Box>
          :
          <></>
          }
        
        <Box flexGrow={1} /> 
       { 
        rol === 3 || rol ===4 ?
        <></>
        :
       <Typography variant="body2" color={getColor(consult)}  >{consult} de 40 consultas por mes</Typography>
       }
        {/* ------------------------------------------- */}
        {/* ------------------------------------------- */}
        {/* Profile Dropdown */}
        {/* ------------------------------------------- */}
        <Box
          sx={{
            width: "1px",
            backgroundColor: "rgba(0,0,0,0.1)",
            height: "25px",
            ml: 1,
          }}
        ></Box>
        <Button
          aria-label="menu"
          color="inherit"
          aria-controls="profile-menu"
          aria-haspopup="true"
          onClick={handleClick4}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid container spacing={1} justifyContent="center" alignItems="center">
              <Grid item >
                <Avatar
                src={currentView === 'Tunueva.casa' ?  TNCLOGO :user}
                alt='avatar'
                sx={{
                  width: "30px",
                  height: "30px",
                }}
              />
              </Grid>

              <Grid item>
              {
              nombre === "" ?
              <Typography  variant="body1" color="black"  gutterBottom>Nombre de usuario</Typography>
              :
              <Typography  variant="body1" color="black"  gutterBottom>{nombre}</Typography>
              }
              </Grid>

            </Grid>
            
          </Box>
        </Button>
        <Menu
          id="profile-menu"
          anchorEl={anchorEl4}
          keepMounted
          open={Boolean(anchorEl4)}
          onClose={handleClose4}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          sx={{
            "& .MuiMenu-paper": {
              width: "250px",
              right: 0,
              top: "70px !important",
            },
          }}
        >
          {currentView === 'Admin' ? 
          null
          :
          <MenuItem onClick={navigatePerfil}>
            <Avatar
            src={currentView === 'Tunueva.casa' ?  TNCLOGO :user}
              sx={{
                width: "35px",
                height: "35px",
              }}
            />
            <Box
              sx={{
                ml: 2,
              }}
            >
              Mi Cuenta
            </Box>
          </MenuItem>
          }
          <Divider />
          {/*<MenuItem onClick={handleClose4}>
            <ListItemIcon>
              <PersonAddOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Add another account
            </MenuItem>*/}
          {/*<MenuItem onClick={handleClose4}>
            <ListItemIcon>
              <SettingsOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Settings
            </MenuItem>*/}
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Cerrar Sesión
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
export function UpdateCountAPI() { };
