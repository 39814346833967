import React from "react";
import {createTheme, ThemeProvider} from '@mui/material/styles';

const ThemeColor = ({children}) =>{
    const theme =createTheme ({
        palette: {
            
                orange: {
                    main: '#F6641F',
                    
                  },
                red: {
                    main: '#FC292B',
                    
                  },
                blue:{
                    main: "#1E386E"
                  },
                
                white:{
                    main:"#FFFFFF"
                },
            
        },
    });
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
};
export default ThemeColor;