import  React, {useState} from 'react';
import {Grid, TextField, Autocomplete} from '@mui/material';
import {Exterior} from '../../../../Model/Exterior';
import AutoCompletStyle from './AutoCompletStyle';
import {updateExterior} from './PrompComplete';

export default function  AutoCompletExterior() {
  const [tipoExterior, setTipoExterior] = useState(null);
 
  //Variable que recorre la lista de estilos de decoración
  const DataExterior = Exterior.map((data)=>data);
 
  return (
    <div>
     
      <Grid container spacing={1} >
        <Grid item>
            <Autocomplete
                size="small"
                //value={tipoExterior}
                onChange={(event, newValue) => {
                  if(newValue){
                    setTipoExterior(newValue.exterior);
                    updateExterior(newValue.traduction);
                  }else{
                    setTipoExterior(null);
                    updateExterior("");
                  }
                    
                 
                }}
                id="controllable-states-demo"
                options={DataExterior}
                getOptionLabel={((option)=>option.exterior)}
                sx={{ width: 225, margin:"auto" }}
                renderInput={(params) => <TextField {...params} label="Define tu exterior" />}
            />
        </Grid>

        <Grid item>
           {tipoExterior !== null ?
            <AutoCompletStyle/>
           :
            null
           }
        </Grid>

      </Grid>
      

           
    </div>
  );
}