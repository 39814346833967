
import ImageRounded from "@mui/icons-material/ImageRounded";

const Menuitems = [
  {
    title: "Tunueva.casa",
    icon: ImageRounded,
    href: "/Tunueva.casa",
    vista: "Tunueva.casa"
  },
  {
    title: "AMIC",
    icon: ImageRounded,
    href: "/AMIC",
    vista: "AMIC"
  },
  {
    title: "VIP",
    icon: ImageRounded,
    href: "/VIP",
    vista: "VIP"
  },
  
];

export default Menuitems;
