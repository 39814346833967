import React from "react";
import {Box, Button, Typography} from '@mui/material';
import LogoIcon from '../../../assets/images/logo-renovarte.png';
const LogoRenovArte = () =>{

return (
    <Box pb={5} mt={5} >
      <Box
        p={2}
        sx={{
          backgroundColor: (theme) => theme.palette.primary.light,
          borderRadius: "10px",
        }}
        style={{ position: "relative" }}
      >
        <img
          src={LogoIcon}
          alt='RenovArte.png'
          style={{
            width:'200px',
            position: "absolute",
            right: "0",
            top: "15px",
          }}
        />
        <Box pb={1} pt={2} sx={{ width: "100%" }}>
          <Typography
            variant="h5"
            mb={2}
            sx={{
              color: (theme) => theme.palette.secondary.main,
              zIndex: "9",
              position: "relative",
            }}
          >
             <br />
          </Typography>
          
        </Box>
      </Box>
    </Box>

);
};
export default LogoRenovArte;