const TipoHabitacion = [
   { habitacion: "Atico", traduction:"penthouse" },
   { habitacion: "Balcón", traduction:"balcony" },
   { habitacion: "Baño", traduction:"bath" },
   { habitacion: "Bar", traduction:"bar" },
   { habitacion: "Biblioteca", traduction:"library" },
   { habitacion: "Cafeteria", traduction:"coffee shop" },
   { habitacion: "Cocina", traduction:"kitchen" },
   { habitacion: "Comedor",traduction:"dining room" },
   { habitacion: "Cuarto de niños", traduction:"children's room" },
   { habitacion: "Cuarto de lavado", traduction:"laundry room" },
   { habitacion: "Despacho", traduction:"office" },
   { habitacion: "Dormitorio", traduction:"bedroom" },
   { habitacion: "Espacios de coworking", traduction:"coworking spaces" },
   { habitacion: "Fachada", traduction:"facade" },
   { habitacion: "Garage", traduction:"garage" },
   { habitacion: "Gimnasio", traduction:"gym" },
   { habitacion: "Jardin", traduction:"garden" },
   { habitacion: "Oficina en Casa", traduction:"home office" },
   { habitacion: "Patio ", traduction:"courtyard" },
   { habitacion: "Sala de Estar", traduction:"living room" },
   { habitacion: "Sala de Juegos", traduction:"game room" },
   { habitacion: "Sala de reuniones", traduction:"meeting room" },
   { habitacion: "Sótano",traduction:"basement" },
   { habitacion: "Taller", traduction:"workshop" },
   { habitacion: "Vestíbulo", traduction:"lobby" },
   { habitacion: "Vestidor", traduction:"dressing room" }, 
];
export default TipoHabitacion;