
export function almacerEnCacheModelo(DatosDeImagen, options){
   const numeroRegistros = localStorage.length;
   if(typeof DatosDeImagen == 'object' ){
      try{
        DatosDeImagen.options = `${options}`;
         localStorage.setItem(`generateImage ${numeroRegistros + 1}`, JSON.stringify(DatosDeImagen));
          
      }catch(error){
         console.error("Error al querer almacenar en local storage:", error);
      }
      
   }else{
      console.error("Invalid data type for local storage. Please provide an object.");
   }
   
};
export function recuperarTodosLosRegistros() {
   const registros = [];
 
   // Recorrer todas las claves en Local Storage
   for (let i = 0; i < localStorage.length; i++) {
     const clave = localStorage.key(i);
 
     // Si la clave empieza con "generateImage", obtener el valor
     if (clave.startsWith("generateImage")) {
       const datosJSON = localStorage.getItem(clave);
       const registro = JSON.parse(datosJSON);
       registros.push(registro);
     }
   }
 
   // Retornar la lista de registros de manera ordenada
   registros.sort((a, b) => a.id - b.id);
   return registros;
 };

 export function eliminarRegistroPorId(idAEliminar) {
   // Iterar sobre todos los elementos en el localStorage
   for (let i = 0; i < localStorage.length; i++) {
     const key = localStorage.key(i);
     
     // Verificar si la clave comienza con "generateImage"
     if (key.startsWith("generateImage")) {
       try {
         // Obtener y parsear el valor almacenado
         const valor = JSON.parse(localStorage.getItem(key));
         
         // Verificar si el id coincide con el que queremos eliminar
         if (valor.id === idAEliminar) {
           // Eliminar el elemento del localStorage
           localStorage.removeItem(key);
           //console.log(`Registro con ID ${idAEliminar} eliminado exitosamente.`);
           return true; // Indicar que se eliminó exitosamente
         }
       } catch (error) {
         console.error(`Error al procesar el elemento ${key}:`, error);
       }
     }
   }
   
   console.log(`No se encontró ningún registro con el ID ${idAEliminar}.`);
   return false; // Indicar que no se encontró el registro
 };
 
 //Función para poder almacenar número de telefono
 export function almacenarTelefono (valor){
   localStorage.setItem("telefono", valor);
 };
 
 //Función para poder obtener el número de telefono
export function recuperaTelefono (){
   const telefono = localStorage.getItem("telefono");
   return telefono;
};

 //Función para poder eliminar el número de telefono
 export function eliminarTelefono (){
   localStorage.removeItem("telefono");
   
};
//Función para poder almacenar número de telefono
export function almacenarCorreo (valor){
   localStorage.setItem("correo", valor);
 };
 
 //Función para poder obtener el número de telefono
export function recuperaCorreo (){
   const correo = localStorage.getItem("correo");
   return correo;
};

 //Función para poder eliminar el número de telefono
 export function eliminarCorreo (){
   localStorage.removeItem("correo");
   
};

//Función para poder almacenar Tipo de usuario
export function almacenarTipoUsuario (valor){
  localStorage.setItem("tipoUsuario", valor);
};

//Función para poder obtener el Tipo de usuario
export function recuperaTipoUsuario (){
  const correo = localStorage.getItem("tipoUsuario");
  return correo;
};

//Función para poder eliminar el Tipo de usuario
export function eliminarTipoUsuario (){
  localStorage.removeItem("tipoUsuario");
  
};


 
 
export default{
   almacerEnCacheModelo,
   recuperarTodosLosRegistros,
   almacenarTelefono,
   recuperaTelefono,
   eliminarTelefono,
   almacenarCorreo,
   recuperaCorreo,
   eliminarCorreo,
   almacenarTipoUsuario,
   recuperaTipoUsuario,
   eliminarTipoUsuario

};
