import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { baseTheme } from "./assets/global/Theme-variable";
import ThemeRoutes from "./routes/Router";
import Spinner from "./views/Spinner/Spinner";
import { AuthProvider } from "./routes/AuthContext";

const App = () => {
  let routing = useRoutes(ThemeRoutes);
  let theme = baseTheme;

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Suspense fallback={
          <Spinner />
        }>
          {routing}
        </Suspense>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;