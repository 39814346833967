const StylesItems =[
    {
        title: "Art Deco",
        styles: [
            {style:"Colores vibrantes"},
            {style:"Líneas geométricas audaces"},
            {style:"Refleja el lujo y la sofisticación de los años 20 y 30"},
            {style:"Uso de metales como el oro"},
            {style:"Uso de metales como el acero"},
        ]   
    },
    {
        title: "Bohemio",
        styles: [
            {style:"Colores vibrantes"},
            {style:"Patrones eclécticos"},
            {style:"Plantas"},
        ]
    },
    {
        title: "Costero",
        styles: [
            {style:"Ambiente fresco"},
            {style:"Ambiente relajado"},
            {style:"Colores claros"},
            {style:"Colores con tejidos naturales"},
            {style:"Colores desgastadas por el sol "},
            {style:"Colores maderas"},
            {style:"Inspirado en la playa y el mar"}, 
        ]   
    },
    {
        title: "Cyberpunk",
        styles: [
            {style:"Ambiente futuristas"},
            {style:"Ambiente futuristas y tecnologico"},
            {style:"Formas Geométricas"},
            {style:"Hologramas visuales"},
            {style:"Luces led"},
            {style:"Muebles metalicos"},
            {style:"Muebles de plastico"},
            {style:"Muebles con material acrílico"},
            {style:"Puertas de vidrio"},
            {style:"Ventanas de vidrio"},   
        ]   
    },
    {
        title: "Eléctrico",
        styles: [
            {style:"Ambiente relajado"},
            {style:"Ambiente fresco"},
            {style:"Colores claros"},
            {style:"Colores maderas"},
            {style:"Colores desgastadas por el sol "},
            {style:"Colores con tejidos naturales"},
            {style:"Mezcla elementos de varios estilos para crear un aspecto único"},
        ]   
    },
    {
        title: "Escandinavo",
        styles: [
            {style:"Colores neutros"},
            {style:"Funcionalidad"},
            {style:"Lineas limpias"},
            {style:"Madera clara"},
            {style:"Minimalista"},
            {style:"Simple"},
        ]
    },
    {
        title: "Fiesta mexicana",
        styles: [
            {style:"Alebrijes"},
            {style:"Guirnaldas de chiles artificiales"},
            {style:"Papel picado"},
            {style:"Piñatas tradicionales"},
            {style:"Sarapes o manteles con diseño mexicano"},
            {style:"Sombreros mexicanos decorativos"},
            {style:"Vasijas de barro"},
        ]   
    },
    {
        title: "Granja",
        styles: [
            {style:"Combina la rusticidad con la comodidad utilizando madera"},
            {style:"Detalles caseros"},
            {style:"Elementos naturales"},
            {style:"Muebles robustos"},
            {style:"Tonos neutros"},
        ]   
    },
    {
        title: "Halloween",
        styles: [
            {style:"Calaveras decorativas"},
            {style:"Calabazas talladas"},
            {style:"Calabazas artificiales"},
            {style:"Cortinas negras"},
            {style:"Cortinas moradas"},
            {style:"Cuadros o carteles temáticos de Halloween"},
            {style:"Esqueletos"},
            {style:"Luces de color naranja"},
            {style:"Luces de color morado"},
            {style:"Murcielagos de papel"},
            {style:"Murcielagos de plastico"},
            {style:"Telarañas artificiales"},
            {style:"Velas negras"},
            {style:"Velas naranjas"},   
        ]   
    },
    {
        title: "Hollywood Regency",
        styles: [
            {style:"Colores brillantes"},
            {style:"Elementos naturales"},
            {style:"Énfasis en el lujo"},
            {style:"Estilo teatral y opulento"},
            {style:"Tonos neutros"},
        ]   
    },
    {
        title: "Industrial",
        styles: [
            {style:"Iluminación estilo fábrica"},
            {style:"Ladrillo a la vista"},
            {style:"Metal"},
        ]
    },
    {
        title: "Japonés",
        styles: [
            {style:"Ambiente simple"},
            {style:"Elemntos naturales"},
            {style:"Espacio tranquilo"},
            {style:"Espacios abiertos"},
            {style:"Líneas limpias"},
        ]   
    },
    {
        title: "Medieval",
        styles: [
            {style:"Ambiente calido"},
            {style:"Armaduras de metal"},
            {style:"Espadas de metal"},
            {style:"Escudos de metal"},
            {style:"Espacios abiertos"},
            {style:"Herramientas de metal"},
            {style:"Iluminación con velas"},
            {style:"Iluminación con antorchas "},
            {style:"Mobiliarios robustos"},
            {style:"Muebles de madera"},
            {style:"Paredes de piedra"},
        ]   
    },
    {
        title: "Mediterráneo",
        styles: [
            {style:"Colores calidos"},
            {style:"Colores arcilla "},
            {style:"Colores terracota"},
            {style:"Colores madera oscura"},
            {style:"España"},
            {style:"Grecia"},
            {style:"Italia"},
            {style:"Inspirado en las regiones costeras"}, 
        ]   
    },
    {
        title: "Minimalista",
        styles: [
            {style:"Espacio despejado"},
            {style:"Funcionalidad"},
            {style:"Lo menos es más"},
        ]
    },
    {
        title: "Moderna",
        styles: [
            {style:"Colores neutros"},
            {style:"Lineas limpias"},
            {style:"Metales"}
        ]
    },
    {
        title: "Navidad",
        styles: [
            {style:"Armonico"},
            {style:"Ambiente minimalista"},
            {style:"Arbol de navidad"},
            {style:"Figuras"},
            {style:"Luces led"},
            {style:"Manteles navideños"},
            {style:"Velas"},
        ]   
    },
    {
        title: "Rústico",
        styles: [
            {style:"Madera neutral"},
            {style:"Piedra"},
            {style:"Texturas acogedoras"}
        ]
    },
    {
        title: "Shabby Chic",
        styles: [
            {style:"Acabados envejecidos"},
            {style:"Combina muebles y accesorios antiguos con otros más modernos para crear un aspecto elegante y desgastado"},
            {style:"Colores pastel"},
        ]
    },
    {
        title: "Transicional",
        styles: [
            {style:"Ambiente sofisticado pero acogedor"},
            {style:"Combina elementos del diseño tradicional y moderno para crear un espacio equilibrado"},
        ]   
    },
    {
        title: "Tradicional",
        styles: [
            {style:"Accesorios clásicos y atemporales"},
            {style:"Muebles de madera maciza"},
            {style:"Telas gruesas y texturizada"},
            ]
    },
    {
        title: "Vintage",
        styles: [
            {style:"Con un toque nostálgico"},
            {style:"Decoraciones de objetos de segunda mano"},
            {style:"Elementos antiguos o de epoca"}, 
        ]
    },
    
    
   
    
  
   
  
   
   
    
   
    
    
   
    

];
export default StylesItems;