import React from 'react';
import { Container, Box} from '@mui/material';
import AppBarContent from '../VistaInicial/VistaInicial-components/AppBar';
import FooterContent from '../VistaInicial/VistaInicial-components/Footer/Footer';
import CrearCuentaForm from './CrearCuenta-Components/Form';


const CrearCuenta =() =>{
    return(
        <div>
            <AppBarContent/>
            <Container component="main" maxWidth="sm" sx={{mb:24}}>
                <Box
                    sx={{
                        width:"100%",
                        marginTop: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: 1, 
                    }}
                >
                <CrearCuentaForm/>
                </Box>
            </Container>
            <FooterContent/>
        </div>
    );
}
export default CrearCuenta;
