import { SERVER_API_URL } from "../rules";

export async function ConsultarUsuarios() {
    try {
      const response = await fetch(`${SERVER_API_URL}/api/selectAllUsers`);
  
      if (!response.ok) {
        const result = await response.json();
        return {usuarios:[], success: true, messageErr: result.message, styleErr: "error"}
      }
      const result = await response.json();
      return  {usuarios:result,success: false, messageErr: result.message, styleErr: "success"}
  
    } catch (error) {
      throw new Error(`API request failed with error': ${error}`);
  
    }
  };

  export async function InsertarUsuario (name, email, phone_number, user_type, password, rol) {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        "name": name,
        "email": email,
        "phone_number": phone_number,
        "user_type": user_type,
        "password": password,
        "rol": rol
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
      };
  
      const response = await fetch(`${SERVER_API_URL}/api/insertUser`, requestOptions);
  
      if (!response.ok) {
        const result = await response.json();
        
        return {insert:false, success: true, messageErr: result.message, styleErr: "error" }
        //console.log(response.json());
        //throw new Error(`API request failed with status': ${response.status}`);
  
      }
      const result = await response.json();
      
      return {insert: true, success: true, messageErr: result.message, styleErr: "success" };
  
    } catch (error) {
      throw new Error(`API request failed with error': ${error}`);
  
    }
    
  };

  export async function EliminarUsuario(id) {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
      };

      const response = await fetch(`${SERVER_API_URL}/api/deletedUser/${id}`, requestOptions);
  
      if (!response.ok) {
        const result = await response.json();
        return {remove: false, success: true, messageErr: result.message, styleErr: "error"}
      }
      const result = await response.json();
      return  {remove: true, success: true, messageErr: result.message, styleErr: "success"}
  
    } catch (error) {
      throw new Error(`API request failed with error': ${error}`);
  
    }
  };

  export async function EditarUsuario(id, name, email, phone_number, user_type, password, rol, session) {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        "name": name,
        "email": email,
        "phone_number": phone_number,
        "user_type": user_type,
        "password": password,
        "rol": rol,
        "session": session
      });
      
      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw
      };

      const response = await fetch(`${SERVER_API_URL}/api/updateUser/${id}`, requestOptions);
  
      if (!response.ok) {
        const result = await response.json();
        return {edit: false, success: true, messageErr: result.message, styleErr: "error"}
      }
      const result = await response.json();
      return  {edit: true, success: true, messageErr: result.message, styleErr: "success"}
  
    } catch (error) {
      throw new Error(`API request failed with error': ${error}`);
  
    }
  };