import React from "react";
import { Alert, Snackbar, AlertTitle } from "@mui/material";
function Alerta(datos){
    
    return (
        
        <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} open={datos.openAlert} autoHideDuration={1}>
            <Alert variant="filled" severity={datos.style} sx={{backgroundColor: datos.style === "error" ?"#F6641F" :"#021456", color:"white"}}>
                <AlertTitle color="white">{datos.style === "error" ? "Error": "Success"}</AlertTitle>
                {datos.message}
            </Alert>
        </Snackbar>

    );
}
export default Alerta;