import { SERVER_API_URL } from "../rules";
import { almacenarCorreo } from "../Model/FuncionAlmacenar";
import {handleNavigate} from '../views/RecuperaPassword/RecuperaPassword-Components/RecuperaPassword-Form';

export async function RecuperaPassword(email){
    try{
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        "correo": email,
      });
  
      var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      };
   
        const response = await fetch(`${SERVER_API_URL}/api/resetpassword`, requestOptions);
        if(!response.ok){
          const result = await response.json();
           
            return {send: false, success: true, messageErr: result.message, styleErr: "error"};
            //console.log('Status:', result);
            //throw new Error(`API request failed with status': ${response.status}`);
           
        }
        const result = await response.json();
        setTimeout(() => {
          
        }, 1000);
        handleNavigate();
        //Si el correo se envia almacenar el correo y teléfono en localStorage
        almacenarCorreo(email);
        return {send: true, success: true, messageErr: result.message, styleErr: "success"};
      
    }catch(error){
     
        console.log("Error: ", error.message);
    }
      
  };