import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography  } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const DialogDeleteUser =(props)=>{
    return(
        <div>
        <Dialog
        sx={{
            margin:'auto',
        }}
        open={props.open}
        onClose={props.handleClose}
    >
        <DialogTitle>Eliminar Usuario</DialogTitle>
        <DialogContent sx={{ scroll: "body" }}>
            <div>
            <DialogContentText>
                Realmente desea eliminar el usuario con el nombre 
            </DialogContentText>
            <Typography fontWeight="bold">{props.name}</Typography>
            </div>
           
            
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose
            }>Cancelar</Button>
            <LoadingButton  loading={props.loading} loadingIndicator="Eliminando"onClick={props.handleDelete}>Eliminar</LoadingButton>

        </DialogActions>
    </Dialog>
    </div>
    );

}
export default DialogDeleteUser;