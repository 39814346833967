import React from "react";
import { Grid, Card, Stack, Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ThemeColor from "../../../../components/Theme/Theme";
import AppBarContent from "../AppBar";
import FooterContent from "../Footer/Footer";
import TunuevaCasaImg from '../../../../assets/images/card-img/RenovArteTunueva.casa.png';
import ImagenTNC1 from '../../../../assets/images/card-img/ImagenTNC1.png';
import ImagenTNC2 from '../../../../assets/images/card-img/ImagenTNC2.png';

const PropuestaTunuevacasa = () => {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate("/Login?vista=Tunueva.casa");

    };

    return (
        <div>
            <AppBarContent />
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                    marginTop:"100px"
                }}

            >
                {/*BLOQUE 1 */}
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mb: 17 }}
                    spacing={2}
                >

                    <Grid item  >
                        <Container maxWidth="sm">
                            <Box>
                                <Typography variant="h1" gutterBottom sx={{ mb: 4, fontWeight: "bold", textAlign: "center" }} >
                                    REINVENTANDO LA COMPRA Y VENTA INMOBILIARIA
                                </Typography>

                                <Typography variant="subtitle1" gutterBottom sx={{ textAlign: "justify", mb: 2 }} >
                                    En Tunueva.casa, estamos transformando la experiencia inmobiliaria al combinar la tradición y
                                    la innovación digital para ofrecer a nuestros usuarios una experiencia de compra y venta sin
                                    precedentes. Nuestro compromiso con el profesionalismo y la integridad nos impulsa a adoptar
                                    las más avanzadas herramientas digitales, asegurando que cada interacción sea eficiente,
                                    transparente y totalmente centrada en las necesidades de nuestra comunidad.
                                </Typography>


                            </Box>
                        </Container>

                    </Grid>

                    <Grid item
                        sx={{
                            display: "flex",
                            alignItems: "stretch",
                        }}>
                        <Card
                            variant="outlined"
                            sx={{
                                maxWidth: 450,
                                p: 0,

                            }}
                        >
                            <img src={TunuevaCasaImg} alt="nevacasa.img" height="100%" width="100%" />
                        </Card>

                    </Grid>

                </Grid>


                {/*BLOQUE 2 */}
                <Container sx={{ mb: 17 }}>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ bgcolor: '#F6641F', marginRight: "5%", marginLeft: "5%", borderRadius: 5, }}>
                        <Typography variant="h1" gutterBottom sx={{ mb: 4, fontWeight: "bold", color: "white", paddingTop: "10%", textAlign: "center" }} >
                            PROFESIONALISMO Y COMPROMISO EN CADA PASO
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom sx={{ textAlign: "center", color: "white", marginRight: "5%", marginLeft: "5%", mb: 4 }} >
                            Creemos firmemente en la creación de valor a largo plazo para nuestros clientes y la comunidad.
                            Con un enfoque en la atención personalizada, garantizamos que cada cliente reciba el apoyo
                            necesario para tomar decisiones informadas y beneficiosas. Desde la fase de contacto inicial
                            hasta el cierre de cada transacción, nuestro equipo se dedica a proporcionar un servicio
                            excepcional que refleja nuestro alto estándar de calidad y respeto por el cliente.
                        </Typography>

                        <Box sx={{ paddingBottom: "5%", }}>
                            <ThemeColor>
                                <Button variant="contained" style={{ color: '#1E386E', fontWeight: "bold", }} color="white" onClick={handleLogin}>
                                    Iniciar Sesión
                                </Button>
                            </ThemeColor>
                        </Box>

                    </Box>
                </Container>


                {/*BLOQUE 3 */}
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mb: 17 }}
                    spacing={2}
                >
                    <Grid item
                        sx={{
                            display: "flex",
                            alignItems: "stretch",
                        }}>
                        <Card
                            variant="outlined"
                            sx={{
                                maxWidth: 450,
                                p: 0,

                            }}
                        >
                            <img src={ImagenTNC1} alt="nevacasa.img" height="100%" width="100%" />
                        </Card>

                    </Grid>

                    <Grid item >
                        <Container maxWidth="sm">
                            <Box>
                                <Typography variant="h1" gutterBottom sx={{ mb: 4, fontWeight: "bold", textAlign: "center" }} >
                                    LIDERANDO LA INNOVACIÓN CON COLABORACIONES ESTRATÉGICAS
                                </Typography>

                                <Typography variant="subtitle1" gutterBottom sx={{ textAlign: "justify" }} >
                                    Tunueva.casa no solo innova de forma independiente; también lideramos el camino hacia el
                                    futuro inmobiliario a través de colaboraciones estratégicas con líderes de la industria de la
                                    construcción y la tecnología. A través de iniciativas como RenovArte, estamos liberando el
                                    potencial de las tecnologías emergentes, como la inteligencia artificial generativa, para
                                    revolucionar cómo los individuos interactúan con las propiedades. Estas alianzas nos permiten
                                    ofrecer soluciones integrales que abarcan desde la visualización de remodelaciones hasta la
                                    realización efectiva de proyectos, siempre con el respaldo de expertos y proveedores certificados.
                                </Typography>
                            </Box>
                        </Container>
                    </Grid>

                </Grid>

                {/*BLOQUE 4 */}

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mb: 17 }}
                    spacing={2}
                >

                    <Grid item >
                        <Container maxWidth="sm">
                            <Box>
                                <Typography variant="h1" gutterBottom sx={{ mb: 4, fontWeight: "bold", textAlign: "center" }} >
                                    COMPROMETIDOS CON LA COMUNIDAD Y LA INNOVACIÓN
                                </Typography>

                                <Typography variant="subtitle1" gutterBottom sx={{ textAlign: "justify", mb: 3 }} >
                                    Nuestro enfoque en la comunidad y la innovación no es solo parte de nuestra estrategia
                                    empresarial; es el núcleo de nuestra identidad. A través de proyectos colaborativos y el
                                    compromiso con el desarrollo sostenible, Tunueva.casa está estableciendo nuevos estándares en
                                    la industria, asegurando que cada paso hacia el futuro sea un paso hacia una comunidad más
                                    conectada y apoyada.<br />
                                    En Tunueva.casa, no solo transformamos propiedades, transformamos vidas, empoderando a
                                    nuestros usuarios para que vean y realicen el verdadero potencial de sus hogares.
                                </Typography>

                                <Box sx={{ alignContent: "center", paddingLeft: "35%" }}>
                                    <ThemeColor>
                                        <Button variant="contained" style={{ color: 'white' }} color="blue" onClick={handleLogin}>
                                            Iniciar Sesión
                                        </Button>
                                    </ThemeColor>
                                </Box>
                            </Box>
                        </Container>
                    </Grid>

                    <Grid item
                        sx={{
                            display: "flex",
                            alignItems: "stretch",
                        }}>
                        <Card
                            variant="outlined"
                            sx={{
                                maxWidth: 450,
                                p: 0,

                            }}
                        >
                            <img src={ImagenTNC2} alt="nevacasa.img" height="100%" width="100%" />
                        </Card>

                    </Grid>

                </Grid>
            </Stack>

            <FooterContent/>
        </div>

    );

};
export default PropuestaTunuevacasa;