import React, {useState, useEffect} from "react";
import {Button, Grid, TextField } from "@mui/material";
import { ConsultarPerfil } from "../../../API/Perfil";
import {updateUsuario} from "../../../API/Perfil";
import Alerta from "../../../components/AlertaComponent/Alerta";
import Spinner from "../../Spinner/Spinner";

const FormPerfil = () =>{
    const [disabled, setDisabled] = useState(true);
    const [nombre, setNombre] = useState("");
    const [correo, setCorreo] = useState("");
    const [telefono, setTelefono] = useState("");
    const [password, setPassword] = useState("");

    const [error, setError] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [style, setStyle] = useState("");
    const [isLoading, setIsloading] = useState(true);

    const email  = sessionStorage.getItem('correo');

    useEffect(()=> {
        ConsultarUsuario();
    },[]);

    const ConsultarUsuario = async()=>{
      try{  
        const {usuario, success, messageErr, styleErr} = await ConsultarPerfil(email);
        if(success){
            MostrarAlerta();
            setMessage(messageErr);
            setStyle(styleErr);
            return;
        }
        if(!success){
            setNombre(usuario.nombre);
            setCorreo(usuario.correo);
            setTelefono(usuario.telefono);
            setPassword(usuario.password);
            setIsloading(false);
            return;
          }
    }catch(err){
        MostrarAlerta();
        setMessage(err.message);
        setStyle("error");
        isLoading(false);
        return;
    }

    };

    const enviarDatos = async () =>{
      if(password === ''){
        setError(true);
        MostrarAlerta();
        setMessage("Debe completar el campo");
        setStyle("error");
        return;
      }
      setError(false);
      setIsloading(true);

      try{
        const { success, messageErr, styleErr} = await updateUsuario(password, email);
        if(success){
          MostrarAlerta();
          setMessage(messageErr);
          setStyle(styleErr);
          return;
        }
      }catch(err){
        MostrarAlerta();
        setMessage("Ocurrio un error");
        setStyle("error");
        
      }finally{
        setDisabled(true);
        setIsloading(false);
      }
    };

    const handleEdit = () =>{
        setDisabled(false);
    };

    const handleEditCancel = () =>{
        setDisabled(true);

    };

    //Función para mostrar la Alerta de validación
  const MostrarAlerta = () => {
    setOpenAlert(true);
    setTimeout(() => {
      setOpenAlert(false);
    }, 3000)
  };

    return (
        <div>
            {isLoading ? <Spinner/>
             :
              <form>
            {/*<TextField
              id="default-value"
              label="Nombre de usuario"
              variant="outlined"
              defaultValue={nombre}
              error={error}
              onChange={(e) => setNombre(e.target.value)}
              fullWidth
              disabled={disabled}
              sx={{
                mb: 2,
              }}
            />

            <TextField
              id="email-text"
              label="Correo Electrónico"
              type="email"
              variant="outlined"
              defaultValue={correo}
              onChange={(e) => setCorreo(e.target.value)}
              disabled={disabled}
              fullWidth
              sx={{
                mb: 2,
              }}
            />

            <TextField
                    id="outlined-basic"
                    label="Teléfono"
                    variant="outlined"
                    type="phone"
                    defaultValue={telefono}
                    //value={telefono}
                    disabled={disabled}
                    error={error}
                    onChange={(e) => setTelefono(e.target.value)}
                    fullWidth
                    sx={{
                        mb: 2,
                    }}
                />
                  */}

            <TextField
              id="outlined-password-input"
              label="Password"
              type="password"
              autoComplete="current-password"
              variant="outlined"
              defaultValue={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={disabled}
              fullWidth
              sx={{
                mb: 2,
              }}
            />

            <div>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}
                    sx={{paddingRight:"5%", paddingTop:"2%"}}                 
                >
                    {disabled && <Grid item>
                    <Button color="secondary" variant="contained" onClick={handleEdit}>
                        Editar Campos
                    </Button>
                    </Grid>
                    }

                    {!disabled && <Grid item>
                    <Button color="error" variant="contained" onClick={handleEditCancel}>
                        Cancelar
                    </Button>
                    </Grid>
                    }

                    {!disabled &&<Grid item>
                    <Button color="success" variant="contained" onClick={enviarDatos}>
                        Guardar
                    </Button>
                    </Grid>
                    }
                    

                </Grid>
              
            </div>
            </form>
            }
            {openAlert && <Alerta openAlert={openAlert} message={message} style={style} />}

        </div>

    );

}
export default FormPerfil;