import React from 'react';
import {Box, Container} from '@mui/material';
import AppBarContent from '../VistaInicial/VistaInicial-components/AppBar';
import FooterContent from '../VistaInicial/VistaInicial-components/Footer/Footer';
import RecuperaPasswordForm from './RecuperaPassword-Components/RecuperaPassword-Form';

const RecuperaPassword = () =>{
    return (
        <div>
            <AppBarContent/>

                <Container component="main" maxWidth="sm" sx={{mb:24}}>
                    <Box
                        sx={{
                            width:"100%",
                            marginTop: 20,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderRadius: 1, 
                        }}
                    >
                    <RecuperaPasswordForm/>
                    </Box>
                </Container>

            <FooterContent/>

        </div>

    );


};
export default RecuperaPassword;
