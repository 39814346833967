import React from "react";
import {CardContent, Box} from "@mui/material";
import MessageInput from "./MessageInput";

const MessageCard = () => {
    return (
            <CardContent 
            variant="outlined"
               sx={{
                p: 0,
                width: "100%",
                margin:"auto"
              }}>
                <MessageInput/>
            </CardContent>
            
        
    );

}
export default MessageCard;
