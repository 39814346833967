import React,{createContext, useState} from "react";
import { createSession } from "../API/Login";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

//Función que permitira identificar si el usuario esta autnticaado usndo las fuciones como contexto
export const AuthProvider = ({children}) => {
    //const [isAutenticated, setIsAutenticated] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);
    const [veryfyClave, setVeryfyClave] = useState(false);
    const isAutenticated = sessionStorage.getItem('login');
    const userRole = JSON.parse(sessionStorage.getItem('userRole'));
    const isAutenticatedAdmin = sessionStorage.getItem('$2b$05$FZk');
    const navigate = useNavigate();


    const email = sessionStorage.getItem('correo');
    //Función para poder indicar que esta autenticado
    const login = (correo, rol)=> {
        //setIsAutenticated(true);
        sessionStorage.setItem('login', true);
        createSession(true, correo);
        sessionStorage.setItem('correo', correo);
        sessionStorage.setItem('userRole', JSON.stringify(rol));
    }; 

    const loginAdmin = (correo)=> {
        //setIsAutenticated(true);
        sessionStorage.setItem('$2b$05$FZk', true);
        createSession(true, correo);
        sessionStorage.setItem('correo', correo);
    };
    //Función para poder cerrar la sesión guardada
    const logoutAdmin = () => {
        //setIsAutenticated(false);
        sessionStorage.removeItem('$2b$05$FZk');
        createSession(false, email);
        navigate('/', { replace: true });
    };

    const logout = () => {
        //setIsAutenticated(false);
        sessionStorage.removeItem('login');
        sessionStorage.removeItem('userRole');
        createSession(false, email);
        navigate('/', { replace: true });
    };
    
    const sendEmailSuccess =()=>{
        setSendEmail(true);
    };
    const sendEmailReset = ()=>{
        setSendEmail(false);
    };

    const veryfyClaveSuccess =()=>{
        setVeryfyClave(true);
    };
    const veryfyClaveReset = ()=>{
        setVeryfyClave(false);
    };


    return (
            <AuthContext.Provider value={{
                isAutenticated, 
                isAutenticatedAdmin, 
                login, 
                logout, 
                sendEmail, 
                sendEmailSuccess,
                sendEmailReset, 
                veryfyClave,
                userRole, 
                veryfyClaveSuccess, 
                veryfyClaveReset, 
                loginAdmin, 
                logoutAdmin
                }}>
                {children}
            </AuthContext.Provider>
    );
};