import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../routes/AuthContext';
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, TextField, InputAdornment, Button, Grid, CircularProgress } from '@mui/material';
import SecureIcon from "@mui/icons-material/LockOutlined";
import UserIcon from "@mui/icons-material/Person2Outlined";
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import CkecIcon from '@mui/icons-material/CheckCircleOutline';
import Alerta from '../../../components/AlertaComponent/Alerta';
import Theme from '../../../components/Theme/Theme';
import { recuperaTelefono, recuperaCorreo, recuperaTipoUsuario } from '../../../Model/FuncionAlmacenar';
import { CreateUsuario } from '../../../API/Login';


const CrearPasswordForm = () => {
    const [nombre, setNombre] = useState("");
    const [password, setPassword] = useState('');
    const telefono = useState(recuperaTelefono);
    const correo = useState(recuperaCorreo);
    const tipoUser = useState(recuperaTipoUsuario);
    const [showPassword] = useState(false);
    const [error, setError] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [style, setStyle] = useState("");
    const [minLienght, setMinLeght] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasUppercase, setHasUpperCase] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const vistaParam = queryParams.get('vista');
    const {login} = useContext(AuthContext);

    const handleCrearPassword = async () => {
        if (nombre === '') {
            setError(true);
            MostrarAlerta();
            setMessage("Debe completar el campo");
            setStyle("error");
            return;
        }
        if (password === '') {
            setError(true);
            MostrarAlerta();
            setMessage("Debe completar el campo");
            setStyle("error");
            return;
        }
        
        if (!isValidPasswordMinLenght(password)) {
            setError(true);
            MostrarAlerta();
            setMessage("Minimo debe de contar con 8 caracteres");
            setStyle("error");
            return;
        }
        if (!isValidPasswordHasNumber(password)) {
            setError(true);
            MostrarAlerta();
            setMessage("Minimo debe de contar con 1 número");
            setStyle("error");
            return;
        }
        if (!isValidPasswordHasUpperCase(password)) {
            setError(true);
            MostrarAlerta();
            setMessage("Minimo debe de contar con al menos una letra mayúscula");
            setStyle("error");
            return;
        }
        
        setError(false);
        setPassword("");
        setIsloading(true);

        try {
            const {auth, success, messageErr, styleErr } = await CreateUsuario(nombre, correo[0], telefono[0], tipoUser[0], password);
            if (success) {
                MostrarAlerta();
                setMessage(messageErr);
                setStyle(styleErr);
                if(auth){
                    login(correo[0], 1); 
                }
                return;

            }
        } catch (err) {
            MostrarAlerta();
            setMessage(err.message);
            setStyle("error");
        } finally {
            setMinLeght(false);
            setHasNumber(false);
            setHasUpperCase(false);
            setIsloading(false);
            //sendEmailReset();
            //veryfyClaveReset();
        }

    };
    //Función para poder tener una contraseña segura
    const handleChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        setMinLeght(newPassword.length >= 8);
        setHasNumber(/\d/.test(newPassword));
        setHasUpperCase(/[A-Z]/.test(newPassword));

    };
    const isValidPasswordMinLenght = (password) => {
        return minLienght;

    };
    const isValidPasswordHasNumber = (password) => {
        return hasNumber;

    };
    const isValidPasswordHasUpperCase = (password) => {
        return hasUppercase;

    };
    //Función para poder navaegar a la vista de inicio
    handleNavigateInicio = () => {
        if(vistaParam === 'Tunueva.casa'){
            navigate("/Tunueva.casa",  { replace: true });
          } 
    };

    //Función para mostrar la Alerta de validación
    const MostrarAlerta = () => {
        setOpenAlert(true);
        setTimeout(() => {
            setOpenAlert(false);
        }, 3000)
    };

    return (
        <div>
            <Box
                sx={{
                    padding: "15px 30px",
                }}
                display="flex"
                alignItems="center"
            >
                <Box flexGrow={1} height="50%">
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: "32px",
                            fontWeight: "200",
                            mb: 2
                        }}
                    >
                        Crea una contraseña
                    </Typography>
                    <Typography
                        variant="h3"
                        sx={{
                            fontSize: "15px",
                            fontWeight: "100",
                            mb: 4
                        }}
                    >

                    </Typography>
                </Box>
            </Box>
            <form>

                <TextField
                id="default-value"
                label="Nombre de usuario"
                variant="outlined"
                value={nombre}
                error={error}
                onChange={(e) => setNombre(e.target.value)}
                fullWidth
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        <UserIcon />
                    </InputAdornment>,

                }}
                color='error'
                sx={{
                    mb: 2,
                }}
                />
                <TextField
                    id="password"
                    label="Contraseña"
                    variant="outlined"
                    value={password}
                    error={error}
                    onChange={handleChange}
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <SecureIcon />
                        </InputAdornment>,

                    }}
                    color='error'
                    sx={{
                        mb: 3,
                    }}
                />
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"

                >

                    <Grid item xs={1}>
                        {isValidPasswordMinLenght(password) ?
                            <CkecIcon sx={{
                                color: "green"
                            }} /> :
                            <ErrorIcon sx={{
                                color: "red"
                            }} />
                        }
                    </Grid>

                    <Grid item xs={11}>
                        <Typography
                            variant="h3"
                            sx={{
                                fontSize: "18px",
                                fontWeight: "200",
                                mb: 2
                            }}
                        >
                            Mínimo debe de contar con 8 catacteres
                        </Typography>
                    </Grid>

                    <Grid item xs={1}>
                        {isValidPasswordHasNumber(password) ?
                            <CkecIcon sx={{
                                color: "green"
                            }} /> :
                            <ErrorIcon sx={{
                                color: "red"
                            }} />
                        }
                    </Grid>

                    <Grid item xs={11}>
                        <Typography
                            variant="h3"
                            sx={{
                                fontSize: "18px",
                                fontWeight: "200",
                                mb: 2
                            }}
                        >
                            Debe contar con al menos un número
                        </Typography>
                    </Grid>

                    <Grid item xs={1}>
                        {isValidPasswordHasUpperCase(password) ?
                            <CkecIcon sx={{
                                color: "green"
                            }} /> :
                            <ErrorIcon sx={{
                                color: "red"
                            }} />
                        }
                    </Grid>

                    <Grid item xs={11}>
                        <Typography
                            variant="h3"
                            sx={{
                                fontSize: "18px",
                                fontWeight: "200",
                                mb: 2
                            }}
                        >
                            Debe contar con al menos una letra mayúscula
                        </Typography>
                    </Grid>

                </Grid>
                {isLoading ?
                    <Box alignItems="center" display="flex">
                    <Box sx={{ paddingLeft: "50%", paddingRight: "50%", }}>
                      <Theme>
                        <CircularProgress color='red' />
                      </Theme>
                      
                    </Box>
                  </Box>
                  :
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleCrearPassword}
                        sx={{
                            mb: 2,
                            backgroundColor: '#FC292B',
                            ":hover":{
                                backgroundColor: '#C21F22'
                            }
                        }}
                    >
                        Crear Contraseña
                    </Button>
                }

            </form>

            {openAlert && <Alerta openAlert={openAlert} message={message} style={style} />}

        </div>
    );

};
export default CrearPasswordForm;
export function handleNavigateInicio() { };