import { handleShowSpinnerStop } from "../views/VerificarEmail/Email";
import {handleNavigate} from "../views/VerificarEmail/VerificarEmail-Componenets/FormVerificar";
import { recuperaCorreo } from "../Model/FuncionAlmacenar";

import { SERVER_API_URL } from "../rules";

export async function VerificarEmail(clave){
  try{
    const email = recuperaCorreo();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "correo": email,
      "claveUsuario": clave,
      
    });

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    };

 
      const response = await fetch(`${SERVER_API_URL}/api/verificar`, requestOptions);
      if(!response.ok){
        const result = await response.json();
          handleShowSpinnerStop();
        
        return {veryfyClave: false,success: true, messageErr: result.message, styleErr: "error"}
         
      }
      const result = await response.json();
      
      handleNavigate();
      handleShowSpinnerStop();
     
      return {veryfyClave: true, success: true, messageErr: result.message, styleErr: "success"}
    
  }catch(error){
      console.log("Error: ", error.message);
  }
    
};