import React, { useState } from "react";
import {
    Grid,
    TextField,
    Box,
    InputAdornment,
    IconButton,
    Fab,
    Input,
    Card,
    CardHeader,
    CircularProgress
} from "@mui/material";
import Button from "../../../components/ButtonComponent/Button";
import ThemeColor from '../../../components/Theme/Theme';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import Photo from "@mui/icons-material/Photo";
import { GetResponseAPI } from "../../../API/GetResponseAPI";
import { UploadImage } from "../../../API/UploadImage";
import ImageCompressor from "../../../components/comressingImgComponent/CompressingImg";
//import Datos from "../../../Model/ModelDatosAEnviar";
import Alerta from "../../../components/AlertaComponent/Alerta";
import {handleShowSpinnerVip} from "../PaginaInicioVIP";
import ClearIcon from '@mui/icons-material/ClearOutlined';

const MessageInput = () => {
    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false);
    const [image, setImage] = useState('');
    const [loading, setLoading] = useState('');
    const [promp, setPromp] = useState('');
    const [error, setError] = useState(false);
    const [style, setStyle] = useState("");
   

    {/*---- Funcion para poder validar el promp para que no sea null ----*/ }
    const EnviarInformacion = async (e) => {
        e.preventDefault();

        if (promp === '') {
            setError(true);
            MostrarAlerta();
            setMessage(" Debes de completar el campo con las instrucciones");
            setStyle("error");
            return;
        }
        if (image === '') {
            setError(true);
            MostrarAlerta();
            setMessage("Debes agregar una imagen en formto jpg/png");
            setStyle("error");
            return;
        }
        handleShowSpinnerVip();
        // Enviamos la información...
        try{ 
            await GetResponseAPI(image, promp);
          }catch(error){
            return alert('Esta imagen desafortunadamente no podemos identificarla, prueba con otra o intentalo de nuevo');
          }
        //Limpiar los campos despues de enviar los datos
        setError(false);
        setPromp('');
        setImage('');

    };

    /*---- Funcion para poder seleccionar la imagen ----*/ 
    const hanldeImageUpload = async (event) =>{
        const file = event.target.files[0];
        if(!file) return;
        setLoading(true);

        try{
            const processed = await ImageCompressor(file);
            const base64Image = await convertToBase64(processed);
            await handleToServer(base64Image);
            //console.log('Tamaño original:', (file.size / 1024).toFixed(2), 'KB');
            //console.log('Tamaño procesado:', (processed.size/ 1024).toFixed(2), 'Kb');
        }catch(err){
            
        }finally{
            setLoading(false);
        }
    };

     //Convertir a base64
     const convertToBase64 = (file) =>{
        return new Promise((resolve, reject)=> {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () =>resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleClearImage =()=>{
        setImage("");
    };

    //Subir al servidor
    const handleToServer = async (base64Image) =>{
        try{ 
            const {image,success, messageErr, styleErr} = await UploadImage(base64Image);
            if (success) {
              MostrarAlerta();
              setMessage(messageErr);
              setStyle(styleErr);
              if(image){
                  setImage(image);
              }
              return;
            }
          }catch(error){
            MostrarAlerta();
            setMessage('Error al procesar o enviar la imagen');
             setStyle('error');
          }
    }
  
   //Función para mostrar la Alerta de validación
    const MostrarAlerta = () => {
        setOpenAlert(true);
        setTimeout(() => {
            setOpenAlert(false);
        }, 3000)
    };
  

    return (
        <form>
             { loading && <Card sx={{
                    width:'310px',
                    height:'245px',

                }}>
                <CardHeader
                    action={
                        <IconButton  aria-label="cleaar"  color="info" onClick={handleClearImage}>
                            <ClearIcon/>
                        </IconButton>
                    }
                    title='Subiendo Imagen'
                />
                <Box
                    sx={{
                        width:'310px',
                        height:'245px',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center'
                    }}
                
                >
                <CircularProgress />
                </Box>
               
                </Card>
            
            }
             {image &&<Card sx={{
                    width:'310px',
                    height:'245x',
            }}>
                <CardHeader
                    action={
                        <IconButton  aria-label="cleaar"  color="info" onClick={handleClearImage}>
                            <ClearIcon/>
                        </IconButton>
                    }
                    title='Imagen seleccionada'
                />
                <img width={300} height={200} src={image} alt="Selected image valid"  />
            
            </Card>
            }
           
            <Grid container direction="row"  justifyContent="center" alignItems="center" spacing={3} >
                {/*-----------------row1 Entrada de texto ----------------------*/}
                <Grid item xs={8.5}>
                   
                        <TextField
                            id="standard-textarea"
                            required
                            error={error}
                            value={promp}
                            onChange={(e) => setPromp(e.target.value)}
                            placeholder="Indicaciones"
                            multiline
                            variant="outlined"
                            fullWidth
                            color="success"
                            sx={{
                                borderRadius:"50px",  
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Box >
                                            <ThemeColor>
                                            <Fab
                                                color="red"
                                                onClick={() => {
                                                    document.getElementById('image-input').click();
                                                }}
                                                sx={{
                                                    ":hover":{
                                                        backgroundColor:"#C21F22"
                                                    },
                                                    height:"200%",
                                                    width:"150%",
                                                    mr: 1,
                                                    mb: {
                                                        xs: 1,
                                                        sm: 0,
                                                        lg: 0,
                                                        margin:'auto',
                                                        
                                                    },
                                                }}
                                        >
                                            <Photo color="white" />
                                            </Fab >
                                            <Input
                                                type="file"
                                                inputProps={{
                                                    accept: 'image/jpeg, image/png',
                                                }}
                                                id="image-input"
                                                style={{ display: 'none',  }}
                                                onChange={hanldeImageUpload}
                                            />
                                            </ThemeColor>
                                        </Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    
                </Grid>
                {/*-----------------row 2 Envió de datios y hacer una petición ----------------------*/}
                <Grid item xs="auto" >
                    <ThemeColor>
                        <Button color="orange" hover="#D65A1A" hadleAction={EnviarInformacion}>
                            <SendOutlinedIcon color="white" />
                        </Button>
                    </ThemeColor>
                    
                </Grid>

            </Grid>
            
            {openAlert && <Alerta openAlert={openAlert} message={message} style={style} />}

        </form>


    );
}
export default MessageInput;