import React, {useState} from "react";
import { Card, CardContent, Box, Typography, Button, Stack } from "@mui/material";
import ExTable from "./AdminComponents/ExTable";
import Header from "../../layouts/FullLayout/Header/Header";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import PDFIcon from "@mui/icons-material/PictureAsPdf";
import DialogFormAddUser from './AdminComponents/DialogFormAddUser';
import { handleExportPDF } from "./AdminComponents/ExTable";
const Admin = () => {
    const [openDialogAddUser, setOpenDialogAddUser] = useState(false);
    const [showRow, setRow] = useState(true);
 
    const handleOpenDialogFormUser = () =>{
        setOpenDialogAddUser(true);
      };
    
      const handleCloseDialogFormUser = () =>{
        setOpenDialogAddUser(false);
      }
      const ExportUsers =  () =>{
          setRow(false);
          setTimeout(() =>{
            handleExportPDF();
          },100);
        
      };

      updateRowState = (value) =>{
        setRow(value);
      };

  return (
      <div>
          <Header sx={{
          backgroundColor: "#ffffff",
        }}/>
        <Box sx={{paddingTop:'100px'}}>
        <Card variant="outlined">
            <CardContent>
                <Box sx={{position:'relative', height:'400px', overflow:'auto'}}>
                    <Box 
                    sx={{
                        position:'sticky',
                        top:0,
                        backgroundColor:'background.paper',
                        zIndex:1,
                        py:2,
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center'
                    }}
                    >
                    <Typography 
                         
                         variant="h2"
                         sx={{fontWeight:'bold'}}
                     >
                         Usuarios Registrados En RenovArte
                     </Typography>
                    <Stack direction="row" spacing={2}>
                    <Button variant="contained" size="small" color="secondary" onClick={handleOpenDialogFormUser} startIcon={<AddIcon/>}>
                         Añadir Usuario
                     </Button>

                     <Button variant="contained" size="small" color="error" onClick={ExportUsers}  startIcon={<PDFIcon/>}>
                         Exportar
                     </Button>

                    </Stack>
                     

                    </Box>
                    
                    <Box
                        sx={{
                        overflow: {
                            xs: "auto",
                            sm: "unset",
                        },
                        }}
                    >
                            <ExTable showRow={showRow}/>
                    </Box>
                </Box>
            </CardContent>
        </Card>
        </Box>
        {openDialogAddUser && 
        <DialogFormAddUser
            open={openDialogAddUser} 
            handleClose={handleCloseDialogFormUser} 
        />
        }
        
      </div>
    
  );
};

export default Admin;
export function updateRowState (){};