
export async function UploadImage(image){
    const API_KEY =process.env.REACT_APP_API_KEY;
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "key": API_KEY,
  "image": image,
  "crop": "false"
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

try{
    const response = await fetch("https://stablediffusionapi.com/api/v3/base64_crop", requestOptions);
    if(!response.ok){
      const result = await response.json();
       return {success: true, messageErr: result.messege, styleErr: "error"}
      //throw new Error(`API request failed with status': ${response.status}`);
    }
    const result = await response.json();
    const init_image = await result.link;
    return {image: init_image,success: true, messageErr: result.messege, styleErr: "success"};
}catch(error){
    //console.log(error);
}
};
