import React, { useState, useContext } from "react";
import {AuthContext} from '../../../routes/AuthContext';
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, TextField, InputAdornment, Button, CircularProgress } from "@mui/material";
import Alerta from "../../../components/AlertaComponent/Alerta";
import KeyIcon from "@mui/icons-material/KeyOff";
import Theme from "../../../components/Theme/Theme";
import { VerificarEmail } from "../../../API/VerificarClave";

const FormVerificar = () => {
  const [codigoEmail, setCodigoEmail] = useState('');
  //const [codigoWhatsapp, setCodigoWhatsapp] = useState('');
  const [error, setError] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState(" ");
  const [style, setStyle] = useState("");
  const [isLoading, setIsloading] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const vistaParam = queryParams.get('vista');
  const {veryfyClaveSuccess} = useContext(AuthContext);

  //Función para poder verificar el código ingresado
  const VerificarCodigo = async () => {
    if (codigoEmail === '') {
      setError(true);
      MostrarAlerta();
      setStyle("error");
      setMessage("Debe completar el campo");
      console.log('vista:', vistaParam);
      return;
    }
   /* if (codigoWhatsapp === '') {
      setError(true);
      MostrarAlerta();
      setStyle("error");
      setMessage("Debe completar el campo");
      return;
    }*/
    setError(false);
    setIsloading(true);

    try {
      const {veryfyClave, success, messageErr, styleErr } = await VerificarEmail(codigoEmail);
      if (success) {
        MostrarAlerta();
        setMessage(messageErr);
        setStyle(styleErr);
        if(veryfyClave){
          veryfyClaveSuccess();
        }
        return;
      }

    } catch (err) {
      MostrarAlerta();
      setMessage("Ocurrio un error");
      setStyle("error");
    } finally {
      setIsloading(false);
    }
    //handleShowSpinner();
  };

  //Función para poder navaegar a la vista de inicio
  handleNavigate = () => {
    if(vistaParam === 'Tunueva.casa-ResetPass' || vistaParam === 'AMIC-ResetPass' || vistaParam === 'VIP-ResetPass'){
      navigate(`/creaNuevoPassword?vista=${vistaParam}`, {replace: true});
    }else if(vistaParam === 'Create-Account'){
      navigate("/crearPassword?vista=Tunueva.casa", { replace: true });
    }else{
      navigate("/", { replace: true });
    }
    
  };

  //Función para mostrar la Alerta de validación
  const MostrarAlerta = () => {
    setOpenAlert(true);
    setTimeout(() => {
      setOpenAlert(false);
    }, 3000)
  };
  return (
    <div>
      <Box
        sx={{
          padding: "15px 30px",
        }}
        display="flex"
        alignItems="center"
      >
        <Box flexGrow={1} height="50%">
          <Typography
            variant="h2"
            sx={{
              fontSize: "32px",
              fontWeight: "200",

              mb: 2
            }}
          >
            Verificar Datos Ingresados
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontSize: "15px",
              fontWeight: "100",
              mb: 4
            }}
          >
            Para poder continuar verifica el codigo que se envió a tu Correo electrónico
          </Typography>
        </Box>
      </Box>
      <form>
        <TextField
          id="outlined-basic"
          label="Código de Correo Electronico"
          variant="outlined"
          type="text"
          value={codigoEmail}
          error={error}
          onChange={(e) => setCodigoEmail(e.target.value)}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">
              <KeyIcon />
            </InputAdornment>,
          }}
          color={vistaParam ==='Tunueva.casa-ResetPass' || vistaParam ==='Create-Account' ? 'error':'primary'}
          sx={{
            mb: 4,
          }}
        />
       {/* <TextField
          id="outlined-basic"
          label="Código de WhatsApp"
          variant="outlined"
          type="text"
          value={codigoWhatsapp}
          error={error}
          onChange={(e) => setCodigoWhatsapp(e.target.value)}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">
              <KeyIcon />
            </InputAdornment>,
          }}
          sx={{
            mb: 4,
          }}
        />*/}


        {isLoading ?
          <Box alignItems="center" display="flex">
            <Box sx={{ paddingLeft: "50%", paddingRight: "50%", }}>
              <Theme>
                <CircularProgress color={vistaParam === 'Tunueva.casa-ResetPass' || vistaParam ==='Create-Account'  ? 'red':'primary'} />
              </Theme>
              
            </Box>
          </Box>
          :
          <Button
            variant="contained"
            fullWidth
            onClick={VerificarCodigo}
            sx={{
              mb: 2,
              backgroundColor: vistaParam === 'Tunueva.casa-ResetPass' || vistaParam ==='Create-Account' ? '#FC292B' :'',
              ":hover":{
                   backgroundColor: vistaParam === 'Tunueva.casa-ResetPass' || vistaParam ==='Create-Account'  ? '#C21F22' : ''
              }
            }}
          >
            Verificar Código
          </Button>
        }


      </form>
      {openAlert && <Alerta openAlert={openAlert} message={message} style={style} />}
    </div>

  );
};

export default FormVerificar;
export function handleNavigate() { };