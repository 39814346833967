import React, {useState} from 'react';
import {Alert, Dialog, DialogContent, Box, Fab, Grid, CircularProgress } from '@mui/material';
import Alerta from '../../../components/AlertaComponent/Alerta';
import { MaxResolution } from '../../../API/DowloadImg';
import ShareIcon from '@mui/icons-material/ShareOutlined';
import DowloadIcon from '@mui/icons-material/Download';
import DialogFormEmail from '../../../components/DialogFormEmail/DialogFormEmail';
import ImageLoader from "../../../components/ImageLoadComponent/ImageLoader";


function DialogImageSelected(datos) {
    const [isDownloading, setIsDownloading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [style, setStyle] = useState("");
    const [openDialogEmail, setOpenDialogEmail] = useState(false);  
    

    //Función para poder descargar las imágenes
    const downloadImg = async () => {
        setIsDownloading(true);
        try {
            const {style, message} = await MaxResolution(datos.img);
            MostrarAlerta();
            setMessage(message);
            setStyle(style);
          } catch (error) {
            MostrarAlerta();
            setMessage("Error al descargar la imagen");
            setStyle("error");
          }finally{
              setIsDownloading(false);
          }
        
    };
    //Función para mostrar la Alerta de validación
  const MostrarAlerta = () => {
    setOpenAlert(true);
    setTimeout(() => {
      setOpenAlert(false);
    }, 3000)
  };

  const handleClickOpen = () =>{
    setOpenDialogEmail(true);
  };

  const handleClose = () =>{
    setOpenDialogEmail(false);
  };
    return (
        <div>
            <Dialog
                open={datos.open}
                onClose={datos.close}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"

            >
                <DialogContent >
                    <ImageLoader
                        src={datos.img}
                        alt="Image"
                        width="100%"
                        height="100%"
                        onClick={() => null}
                        hasLogoWhitemark="true"
                    />
                    {/* <img src={datos.img} width="100%" height="100%" alt="Img" onContextMenu={(e) => e.preventDefault()} /> */}
                    {/* Grid container */}
                    <Grid container justifyContent="flex-end" paddingRight={2} spacing={2}>

                        <Grid item>
                            {/* Box */}
                            <Box >
                                <Fab
                                    onClick={handleClickOpen}
                                    color="success"
                                    sx={{
                                        height: "70%",
                                        width: "140%",
                                        mb: {
                                            margin: 'auto',
                                        },
                                    }}>
                                    <ShareIcon />
                                </Fab>
                            {/* Box End*/}
                            </Box>
                        </Grid>

                        <Grid item>
                            <Box >
                                <Fab
                                    disabled={isDownloading? true : false}
                                    onClick={downloadImg}
                                    color="secondary"
                                    sx={{
                                        height: "70%",
                                        width: "140%",
                                        mb: {
                                            margin: 'auto',
                                        },
                                    }}>
                                   { isDownloading? <CircularProgress color='secondary' size={25}/>:<DowloadIcon /> }
                                </Fab>
                            </Box>
                        </Grid>

                    </Grid>
                    {/* Grid container End */}
                </DialogContent>
              { isDownloading && <Alert variant="outlined" severity="info">
                Se esta obteniendo la imagen con alta resolución, la descarga puede demorar un poco !
                </Alert>
              }
            </Dialog>
            {openAlert &&  <Alerta openAlert={openAlert} message={message} style={style}></Alerta>}
            {openDialogEmail && <DialogFormEmail open={openDialogEmail} handleClose={handleClose} ImgURL={datos.img}></DialogFormEmail>}
        </div >
    );
}
export default DialogImageSelected;



