
import { handleNavigateLogin } from "../views/Login/Login-components/Form";
import { handleNavigateInicio } from "../views/CrearPassword/CrearPassword-Components/CrearPassword-Form";
import { eliminarTelefono, eliminarCorreo, eliminarTipoUsuario } from "../Model/FuncionAlmacenar";
import { ConsultarPerfil } from "./Perfil";
import { SERVER_API_URL } from "../rules";



export async function Login(correo, password, vista) {
 
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "correo": correo,
      "password": password,
      "vista":vista
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    const response = await fetch(`${SERVER_API_URL}/api/inisiosesion`, requestOptions);
      const result = await response.json();
      const messageError = result.message;

    if(response.status === 201){
      return {auth:false, success: false, messageErr: messageError, styleErr: "error" };
    }
    if (!response.ok) {
      return {auth:result.auth, success: true, messageErr: messageError, styleErr: "error" };
      //console.log("Session", result.message);
      //AlertaError(open, errorMessage, style);
      //throw new Error(`API request failed with status': ${response.status}`);

    }
    const {usuario, success} = await ConsultarPerfil(correo);
    if(!success){
      setTimeout(() => {
      }, 1000);
      handleNavigateLogin();
      return {rol:usuario.rol, auth:result.auth, success: true, messageErr: result.message, styleErr: "success", admin:result.admin };
      }
    


  } catch (error) {
    throw new Error(`API request failed with status': ${error}`);
  }

};


export async function CreateUsuario(name, email, phone_number, tipoUser, password) {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "name": name,
      "email": email,
      "phone_number": phone_number,
      "tipoUser": tipoUser,
      "password": password
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    const response = await fetch(`${SERVER_API_URL}/api/createusuario`, requestOptions);

    if (!response.ok) {
      const result = await response.json();
      
      return {auth: false, success: true, messageErr: result.message, styleErr: "error" }
      //console.log(response.json());
      //throw new Error(`API request failed with status': ${response.status}`);

    }
    const result = await response.json();
    eliminarTelefono();
    eliminarCorreo();
    eliminarTipoUsuario();
    setTimeout(() => {
    }, 1000);
    handleNavigateInicio();
    
    return {auth:true, success: true, messageErr: result.message, styleErr: "success" };

  } catch (error) {
    throw new Error(`API request failed with status': ${error}`);

  }

  /*.then(response => response.json())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));*/




};
export async function createSession(session,correo) {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "session": session,
      "correo": correo,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    const response = await fetch(`${SERVER_API_URL}/api/createSession`, requestOptions);

    if (!response.ok) {
      const result = await response.json();
      return {auth: false, success: true, messageErr: result.message, styleErr: "error" }
      //console.log(response.json());
      //throw new Error(`API request failed with status': ${response.status}`);

    }
    const result = await response.json();
    return {auth:true, success: true, messageErr: result.message, styleErr: "success" };

  } catch (error) {
    throw new Error(`API request failed with status': ${error}`);

  }
};