import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button  } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const DialogImgURL =(props)=>{
    return(
        <Dialog
        sx={{
            margin:'auto',
        }}
        open={props.open}
        onClose={props.handleClose}
    >
        <DialogTitle>Ya existe una sesión iniciada</DialogTitle>
        <DialogContent sx={{ scroll: "body" }}>
            <DialogContentText>
                Existe una sesión iniciada para este ususario, desea cerrar la sesión 
            </DialogContentText>
            
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose
            }>Cancelar</Button>
            <LoadingButton  loading={props.loading} loadingIndicator="Cerrando Sesión"onClick={props.handleLogout}>Cerrar Sesión</LoadingButton>

        </DialogActions>
    </Dialog>
    );

}
export default DialogImgURL;