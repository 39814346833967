import React from 'react';
import {Grid, Card, Stack, Container, Box, Typography, Button} from '@mui/material';
import ThemeColor from '../../../../components/Theme/Theme';
import { useNavigate } from 'react-router-dom';
import AppBarContent from "../AppBar";
import FooterContent from "../Footer/Footer";
import amicImg from '../../../../assets/images/card-img/RenovArteAMIC.png';
import ImagenAMIC1 from '../../../../assets/images/card-img/ImagenAMIC1.png';
import ImagenAMIC2 from '../../../../assets/images/card-img/ImagenAMIC2.png';

const PropuestaAMIC = () =>{

    const navigate = useNavigate();

    const handleLogin =() =>{
        navigate("/Login?vista=AMIC");

    };
    return(

        <div>
        <AppBarContent/>
        <Stack
           direction="column"
           justifyContent="center"
           alignItems="center"
           sx={{
                    marginTop:"100px"
               }}
        
        >
       {/*BLOQUE 1 */}
          <Grid 
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{mb:17}}
              spacing={2}
          >
              
              <Grid item >
                  <Container maxWidth="sm"> 
                  <Box>
                      <Typography variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", textAlign:"center"}} >
                          TRANSFORMANDO EL ECOSISTEMA DE LA CONSTRUCCIÓN PARA UN FUTURO SOSTENIBLE 
                      </Typography>
                      
                      <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"justify", mb:3}} >
                        En la Asociación Mexicana de la Industria de la Construcción (AMIC), estamos comprometidos 
                        con la redefinición del ecosistema de la construcción, creando oportunidades de negocio que no 
                        solo son rentables, sino también sostenibles y responsables con el medio ambiente. Nuestra 
                        misión es liderar la transformación hacia prácticas de construcción que respeten y preserven 
                        nuestros recursos naturales, al tiempo que ofrecen soluciones innovadoras y de alta calidad que 
                        satisfacen las demandas de una sociedad cada vez más exigente e informada.
                      </Typography>

                  </Box>
                  </Container>
                 
              </Grid>

              <Grid item 
                    sx={{
                          display: "flex",
                          alignItems: "stretch",
                      }}>
                  <Card 
                  variant="outlined"
                  sx={{
                      maxWidth:450,
                      p: 0,
                     
                   }}
                  >
                  <img src={amicImg}  alt="nevacasa.img" height="100%" width="100%"/>
                  </Card>

              </Grid>

          </Grid>
                  
              {/*BLOQUE 2 */}
              <Typography variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", textAlign:"center"}} >
                  CREANDO VALOR SUSTENTABLE PARA LA SOCIEDAD
              </Typography>
              <Container sx={{mb:4}} >
                  <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"justify", marginRight:"5%", marginLeft:"5%"}} >
                    AMIC está en la vanguardia de las iniciativas que promueven la sostenibilidad en todos los 
                    aspectos de la construcción. Desde el uso de materiales ecológicos hasta la implementación de 
                    tecnologías avanzadas para reducir la huella de carbono, nuestro enfoque está centrado en crear 
                    valor duradero y positivo para la sociedad. Al hacerlo, no solo mejoramos la calidad de nuestras 
                    construcciones, sino que también establecemos nuevos estándares de excelencia y 
                    responsabilidad en la industria.
                  </Typography>
              </Container>

               <Box sx={{alignContent:"center", mb:17}}>
                                <ThemeColor>
                                   <Button variant="contained" style={{ color: 'white' }}  color="blue" onClick={handleLogin}>
                                            Iniciar Sesión
                                    </Button> 
                                </ThemeColor>
                </Box>

              {/*BLOQUE 3 */}
                  <Grid 
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{mb:17}}
                  spacing={2}
              >
                  <Grid item 
                      sx={{
                              display: "flex",
                              alignItems: "stretch",
                          }}>
                      <Card 
                      variant="outlined"
                      sx={{
                          maxWidth:450,
                          p: 0,
                      
                      }}
                      >
                      <img src={ImagenAMIC1}  alt="nevacasa.img" height="100%" width="100%"/>
                      </Card>

                  </Grid>
                  
                  <Grid item >
                      <Container maxWidth="sm"> 
                      <Box>
                          <Typography variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", textAlign:"center"}} >
                              PROFESIONALISMO Y COMPROMISO CON LA COMUNIDAD
                          </Typography>
                          
                          <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"justify"}} >
                            El profesionalismo es fundamental en cada proyecto que lideramos. En AMIC, garantizamos que 
                            todas nuestras actividades y colaboraciones están guiadas por un firme compromiso con la ética, 
                            la transparencia y la excelencia. Este enfoque nos permite construir relaciones sólidas y 
                            confiables con todas nuestras partes interesadas, incluidos socios, clientes y la comunidad en 
                            general.
                          </Typography>
                      </Box>
                      </Container>
                  </Grid>

              </Grid>

                {/*BLOQUE 4 */}

                <Grid 
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{mb:17}}
                  spacing={2}
              >
                  
                  <Grid item >
                      <Container maxWidth="sm"> 
                      <Box>
                          <Typography variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", textAlign:"center"}} >
                              COLABORACIÓN CON LÍDERES DE LA INDUSTRIA
                          </Typography>
                          
                          <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"justify"}} >
                            AMIC no actúa sola. Trabajamos codo a codo con líderes de la industria inmobiliaria y de la 
                            construcción para impulsar cambios significativos que beneficien a todos los niveles de la 
                            sociedad. Estas colaboraciones son esenciales para acelerar la adopción de prácticas sostenibles 
                            en la industria y para garantizar que los beneficios de nuestras innovaciones se compartan 
                            ampliamente.
                          </Typography>
                      </Box>
                      </Container>
                  </Grid>

                  <Grid item 
                      sx={{
                              display: "flex",
                              alignItems: "stretch",
                          }}>
                      <Card 
                      variant="outlined"
                      sx={{
                          maxWidth:450,
                          p: 0,
                      
                      }}
                      >
                      <img src={ImagenAMIC2}  alt="nevacasa.img" height="100%" width="100%"/>
                      </Card>

                  </Grid>

              </Grid>

              <Container  sx={{mb:17}}>
                  <Box  display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ bgcolor: '#F6641F', marginRight:"5%", marginLeft:"5%", borderRadius:5, }}>
                    <Typography variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", color:"white", paddingTop:"10%", textAlign:"center"}} >
                            INNOVACIÓN Y LIDERAZGO PARA UN FUTURO MEJOR
                    </Typography>

                    <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"center", color:"white", marginRight:"5%", marginLeft:"5%", mb:4}} >
                            AMIC no actúa sola. Trabajamos codo a codo con líderes de la industria inmobiliaria y de la 
                            construcción para impulsar cambios significativos que beneficien a todos los niveles de la 
                            sociedad. Estas colaboraciones son esenciales para acelerar la adopción de prácticas sostenibles 
                            en la industria y para garantizar que los beneficios de nuestras innovaciones se compartan 
                            ampliamente.
                    </Typography>

                    <Box sx={{paddingBottom:"5%",}}>
                                <ThemeColor>
                                   <Button variant="contained"  style={{ color: '#1E386E', fontWeight:"bold", }}  color="white" onClick={handleLogin}>
                                            Iniciar Sesión
                                    </Button> 
                                </ThemeColor>
                    </Box>
                    
                  </Box>

              </Container>
        </Stack>
    <FooterContent/>

  </div>
);

};
export default PropuestaAMIC;