import  React, {useState} from 'react';
import {Grid, TextField, Autocomplete} from '@mui/material';
import TipoHabitacion from '../../../../Model/TipoHabitacionData';
import AutoCompletStyle from './AutoCompletStyle';
import {updateHabitacion} from './PrompComplete';

export default function  AutoCompletTipoHabitacion() {
  const [tipoHabitacion, setTipoHabitacion] = useState(null);
 
  //Variable que recorre la lista de estilos de decoración
  const DataHabitacion = TipoHabitacion.map((data)=>data);

  const handleChange = (event, newValue)=>{
    if(newValue){
      setTipoHabitacion(newValue.habitacion);
      updateHabitacion(newValue.traduction);
    }else{
      setTipoHabitacion(null);
    }
}
 
  return (
    <div>
     
      <Grid container spacing={1} >
        <Grid item>
            <Autocomplete
                size="small"
                //value={tipoHabitacion}
                onChange={handleChange}
                id="controllable-states-demo"
                options={DataHabitacion}
                getOptionLabel={(option)=> option.habitacion}
                sx={{ width: 225, margin:"auto" }}
                renderInput={(params) => <TextField {...params} label="Define tu entorno" />}
            />
        </Grid>

        <Grid item>
           {tipoHabitacion !== null ?
            <AutoCompletStyle/>
           :
            null
           }
        </Grid>

      </Grid>
      

           
    </div>
  );
}