import React, { useState, useEffect } from "react";
import { Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, InputAdornment } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Alerta from "../../../components/AlertaComponent/Alerta";
import UserIcon from "@mui/icons-material/Person2Outlined";
import GmailIcon from "@mui/icons-material/EmailOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SecureIcon from "@mui/icons-material/LockOutlined";
import { EditarUsuario } from "../../../API/Admin";
import { handleconsultarUsuarios } from "./ExTable";
import TipoUsuarioData from '../../../Model/TipoUsuarioData';

const DialogFormUpdateUser = (props) => {
    const [error, setError] = useState(false);
    const [nombre, setNombre] = useState(props.name);
    const [email, setEmail] = useState(props.email);
    const [telefono, setTelefono] = useState(props.phonenumber);
    const [password, setPassword] = useState('');
    const [showPassword] = useState(false);
    const [minLienght, setMinLeght] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasUppercase, setHasUpperCase] = useState(false);
    const [userType, setUserType] = useState(props.userType);
    const [rol, setRol] = useState(props.rol);
    const [session, setSession] = useState(props.session);
    const [userTypeValue, setUserTypeValue] = useState(null);
    const [rolValue, setRolValue] = useState(null);
    const [sessionValue, setSessionValue] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [style, setStyle] = useState("");
    const [loading, setLoading] = useState(false);
    const tipoUsuarioData = TipoUsuarioData.map((data)=> data);

    //función para mostrar el valor que se obtiene por parte del Tipo de usuario, Rol y la sesión
    useEffect(()=>{
        const initialUserType = tipoUsuarioData.find(option => option.tipousuario === userType);
        const initialOption = Rol.find(option => option.value === rol);
        const initialSession = Session.find(option => option.value === session);
        setUserTypeValue(initialUserType || null);
        setSessionValue(initialSession || null);
        setRolValue(initialOption || null);
    }, [ userType, rol, session]);
    //Función para poder insetar los datos
    const handleUpdateUser = async () => {
        if (nombre === '') {
            setError(true);
            MostrarAlerta();
            setMessage("Debe completar el campo");
            setStyle("error");
            return;
        }
        if (email === '') {
            setError(true);
            MostrarAlerta();
            setMessage("Debe completar el campo");
            setStyle("error");
            return;
        }
        if (telefono === '') {
            setError(true);
            MostrarAlerta();
            setMessage("Debe completar el campo");
            setStyle("error");
            return;
        }
        if (telefono.length < 10 || telefono.length > 10) {
            setError(true);
            MostrarAlerta();
            setMessage("Ingrese un número de 10 digitos");
            setStyle("error");
            return;
        }
        if(userTypeValue === null){
            setError(true);
            MostrarAlerta();
            setMessage("Debe completar el campo");
            setStyle("error");
            return;
        }
    
        if (rolValue === null) {
            setError(true);
            MostrarAlerta();
            setMessage("Debe completar el campo");
            setStyle("error");
            return;
        }
        if (sessionValue === null) {
            setError(true);
            MostrarAlerta();
            setMessage("Debe completar el campo");
            setStyle("error");
            return;
        }
        setError(false);
        try {
            setLoading(true);
            const { edit, success, messageErr, styleErr } = await EditarUsuario(props.id, nombre, email, telefono, userTypeValue ? userTypeValue.tipousuario : null, password, rolValue ? rolValue.value : null, sessionValue ? sessionValue.value : null);
            if (success) {
                MostrarAlerta();
                setMessage(messageErr);
                setStyle(styleErr);
                if (edit) {
                    setTimeout(() => {
                        props.handleClose();
                    }, 1000)
                    handleconsultarUsuarios();
                    return;
                }
                return;
            }
        } catch (err) {
            setError(true);
            MostrarAlerta();
            setMessage("Ocurrio un erro al insertar los datos");
            setStyle("error")
        } finally {
            setLoading(false);
        }

    };

    //Función para poder tener una contraseña segura
    const handleChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        setMinLeght(newPassword.length >= 8);
        setHasNumber(/\d/.test(newPassword));
        setHasUpperCase(/[A-Z]/.test(newPassword));

    };

    //Validamos que el password cumpla con lo solicitado
    const isValidPasswordMinLenght = (password) => {
        return minLienght;

    };
    const isValidPasswordHasNumber = (password) => {
        return hasNumber;

    };
    const isValidPasswordHasUpperCase = (password) => {
        return hasUppercase;

    };
    
    const updateUserType = (value) =>{
        setUserTypeValue(value);
    };

    const updateRol = (value) => {
        setRolValue(value);
    };
    const updateSession = (value) => {
        setSessionValue(value);
    };
    const Rol = [
        {
            rol: "Tunueva.casa",
            value: 1
        },
        {
            rol: "AMIC",
            value: 2
        },
        {
            rol: "VIP",
            value: 3
        },
        {
            rol: "ADMIN",
            value: 4
        }
    ];
    const Session =[
            {
                value: true
            },
            {
                value: false
            },
    ];

    //Función para mostrar la Alerta de validación
    const MostrarAlerta = () => {
        setOpenAlert(true);
        setTimeout(() => {
            setOpenAlert(false);
        }, 3000)
    };

    return (
        <div>
            <Dialog
                sx={{
                    margin: 'auto',
                }}
                open={props.open}
                onClose={props.handleClose}
                PaperProps={{
                    component: 'form'
                }}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>Actualizar Usuario</DialogTitle>
                <DialogContent sx={{ scroll: "body" }}>
                        <TextField
                            id="default-value"
                            label="Nombre de usuario"
                            variant="outlined"
                            margin="dense"
                            defaultValue={nombre}
                            error={error}
                            onChange={(e) => setNombre(e.target.value)}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <UserIcon />
                                </InputAdornment>,

                            }}
                            sx={{
                                mb: 2,
                            }}
                        />
                        <TextField
                            id="email"
                            label="Correo Electrónico"
                            variant="outlined"
                            type="email"
                            defaultValue={email}
                            error={error}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            InputProps={{
                                startAdornment:
                                    <div>
                                        <InputAdornment position="start">
                                            <GmailIcon />
                                        </InputAdornment>
                                    </div>

                            }}
                            sx={{
                                mb: 2,
                            }}
                        />

                        <TextField
                            id="outlined-basic"
                            label="Ingrese su Número de WhatsApp"
                            variant="outlined"
                            type="phone"
                            defaultValue={telefono}
                            error={error}
                            onChange={(e) => setTelefono(e.target.value)}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <WhatsAppIcon />
                                </InputAdornment>,
                            }}
                            sx={{
                                mb: 2,
                            }}
                        />

                        <TextField
                            id="password"
                            label="Contraseña"
                            variant="outlined"
                            defaultValue={password}
                            error={error}
                            onChange={handleChange}
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <SecureIcon />
                                </InputAdornment>,

                            }}
                            sx={{
                                mb: 2,
                            }}
                        />

                        <Autocomplete
                            fullWidth
                            size="medium"
                            value={userTypeValue}
                            onChange={(event, newValue) => {
                                setUserTypeValue(newValue);
                                updateUserType(newValue);
                            }}
                            options={tipoUsuarioData}
                            getOptionLabel={(option) => option.tipousuario}
                            sx={{ margin: "auto", mb:2 }}
                            renderInput={(params) =>
                                <TextField {...params} error={error}   label="Tipo de Usuario" fullWidth />
                            }
                           
                           
                        />

                        <Autocomplete
                            fullWidth
                            size="medium"
                            value={rolValue}
                            onChange={(event, newValue) => {
                                setRolValue(newValue);
                                updateRol(newValue );
                            }}
                            options={Rol}
                            getOptionLabel={(option) => option.rol}
                            sx={{ margin: "auto", mb: 2, }}
                            renderInput={(params) =>
                                <TextField {...params} error={error}  label="Rol" fullWidth />
                            }
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                        />
                         <Autocomplete
                            fullWidth
                            size="medium"
                            value={sessionValue}
                            onChange={(event, newValue) => {
                                setSessionValue(newValue);
                                updateSession(newValue );
                            }}
                            options={Session}
                            getOptionLabel={(option) => option.value.toString()}
                            sx={{ margin: "auto" }}
                            renderInput={(params) =>
                                <TextField {...params} error={error} label="Session" fullWidth />
                            }
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                        />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose
                    }>Cancelar</Button>
                    <LoadingButton loading={loading} loadingIndicator="Actualizando" onClick={handleUpdateUser}>Actualizar</LoadingButton>

                </DialogActions>
            </Dialog>
            {openAlert && <Alerta openAlert={openAlert} message={message} style={style} />}
        </div>
    );

}
export default DialogFormUpdateUser;