import React from 'react';
import { useNavigate } from 'react-router-dom';
import {Button, Grid, Stack, Typography} from '@mui/material';
import CardAmic from './VistaInicial-components/CardAmic';
import CardTunuevacasa from './VistaInicial-components/CardTunuevacasa';
import CardNuevoAliado from './VistaInicial-components/CardNuevoAliado';
import CardVIP from './VistaInicial-components/CardVIP';
import AppBarContent from './VistaInicial-components/AppBar';
import FooterComponent from './VistaInicial-components/Footer/Footer';

const VistaInicial = () =>{
    const navigate = useNavigate();

    const handleNavigate = () =>{
        navigate("/Renovarte")
    };

    return(
        <div>
            <AppBarContent/>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{
                    marginTop:"100px",
                    mb:10
                }}
            >

                <Typography sx={{textAlign:"center"}} variant="h1" color="grey" >
                    Descubre El Potencial Oculto De Tu Espacio Con RenovArte
                </Typography>

                <Typography  sx={{textAlign:"center",  fontWeight:"bold"}} variant="h3" gutterBottom  >
                    Transforma Tu Hogar o Departamento en Segundos
                </Typography>

                <Button onClick={handleNavigate} variant="outlined" color="secondary" size="large">
                            ¿ Qué es RenovArte ?
                </Button>
               
                

            <Grid 
                container  
                direction="row" 
                rowSpacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{ 
                    margin:"auto",
                    display: "flex",
                    alignItems: "stretch",
                    mb:10
                }}
                //style={{ minHeight: '100vh' }} 
            >
                <Grid item >
                    <CardTunuevacasa/>
                </Grid>

                <Grid item >
                    <CardAmic/>
                </Grid>

                <Grid item>
                    <CardNuevoAliado/>
                </Grid>

                <Grid item>
                    <CardVIP/>
                </Grid>
            </Grid>
            </Stack>
            <FooterComponent/>
        </div>

    );

}
export default VistaInicial;