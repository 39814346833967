import  React, {useState} from 'react';
import {Grid, TextField, Autocomplete} from '@mui/material';
import StylesItems from '../../../../Model/HabitacionStylesData';
import { updateEstilo, updateDecoracion } from './PrompComplete';



export default function AutoCompletStyle() {
  const [title, setTitle] = useState(null);
  const [styles, setStyles] = useState([]);
  const [index, setIndex] = useState(0);
 //Función que permite pasar el numero de arreglo seleccionado comparando el titulo para acceder a los estilos
  const handleChange = (value) => {
    if(!value){
      setIndex(0);
      return;
    }
    const newIndex = StylesItems.findIndex((item) =>item.title === value);
            setIndex(newIndex);
  };
  //Variable que recorre la lista de estilos de decoración
  const StyleData = StylesItems.map((data)=>data.title);
  const StylesData = StylesItems[index].styles.map((item)=>item.style);
  return (
    <div>
      
      <Grid container spacing={1} >
        <Grid item>
            <Autocomplete
                size="small"
                value={title}
                onChange={(event, newValue) => {
                    setTitle(newValue);
                    handleChange(newValue);
                    setStyles([]);
                    updateEstilo(newValue);
                    updateDecoracion("");
                }}
                id="controllable-states-demo"
                options={StyleData}
                sx={{ width: 225, margin:"auto" }}
                renderInput={(params) => <TextField {...params} label="Estilo de entorno" />}
            />
        </Grid>

        <Grid item>
            {title !== null ? 
            <Autocomplete
                size="small"
                multiple
                value={styles}
                onChange={(event, newValue) => {
                  if(newValue){
                    setStyles(newValue);
                    updateDecoracion(newValue);
                  }else{
                    setStyles("");
                    updateDecoracion("");
                  }
                    
                }}
                id="tags-outlined"
                options={StylesData}
                sx={{ width: 225, margin:"auto" }}
                renderInput={(params) => <TextField {...params} label="Decoración de entorno" placeholder='Decoración de entorno' />}
            />:
            null
            }
            
        </Grid>

      </Grid>
      

           
    </div>
  );
}