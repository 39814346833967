import React, { useState } from 'react';
import { Container, Box } from '@mui/material';
import FormVerificar from './VerificarEmail-Componenets/FormVerificar';
import AppBarContent from '../VistaInicial/VistaInicial-components/AppBar';
import FooterContent from '../VistaInicial/VistaInicial-components/Footer/Footer';
import Spinner from '../Spinner/Spinner';

const Email = () => {
    const [showSpinner, setShowSpinner] = useState(false);

    handleShowSpinner = () => {
        setShowSpinner(true);
    };
    handleShowSpinnerStop = () => {
        setShowSpinner(false);
    };
    return (
        <div>
            <AppBarContent/>
            <Container component="main" maxWidth="sm" sx={{mb:24}}>
                <Box
                    sx={{
                        width: "100%",
                        marginTop: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: 1,
                    }}
                >
                {!showSpinner && <FormVerificar />}
                </Box>
                {showSpinner && <Spinner />}
            </Container>
           <FooterContent/>


        </div>
    );
}
export default Email;
export function handleShowSpinner() { };
export function handleShowSpinnerStop() { };