import React, {useState} from "react";
import {Box,} from "@mui/material";
//import MessageCard  from "./paginainicio-components/MessageCard";
//import ImagenRespuesta  from "./paginainicio-components/ImagenRespuesta";
import UsuarioMessage from "./paginainicioArqui-components/UsuarioMessage";
import FullLayout from "../../layouts/FullLayout/FullLayout";
import Spinner from "../Spinner/Spinner";
import PrompComplete from './paginainicioArqui-components/Autocomplet-Components/PrompComplete';

const PaginaInicio =() => {
    const [showSpinner, setShowSpinner] = useState(false);

    handleShowSpinnerArqui = () =>{
        setShowSpinner(true);
    };
    handleShowSpinnerStopArqui = () =>{
        setShowSpinner(false);
    };
   
    return (
        <div>
        <FullLayout>
         {!showSpinner && <UsuarioMessage/>}
          {showSpinner &&<Spinner/> }
         <Box height={10}/>   
         
        {!showSpinner && <PrompComplete/>}
        </FullLayout>
        </div>
        
    );
}
export default PaginaInicio;
export function handleShowSpinnerArqui() { };
export function handleShowSpinnerStopArqui() { };
